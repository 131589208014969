import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../components/Spacing';
import { pageTitle } from '../helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Contact from '../components/Contact';

export default function Home() {
  const { t } = useTranslation();
  pageTitle('Home');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="75" md="75" />
      <div
        className="cs-hero cs-bg"
        style={{ backgroundImage: 'url(./images/hero_bg.jpeg)' }}
      >
        <div className="cs-hero_in cs-container_wrap">
          <div className="cs-container">
            <div className="cs-hero_text">
              <h1 className="cs-hero_title">{t('hero_title')}</h1>
              <div className="cs-hero_subtitle">{t('hero_subtitle')}</div>
              <Link to="/register" className="cs-btn cs-style1">
                {t('hero_btn_text')}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="195" md="70" />
      <div className="cs-container">
        <div className="cs-row cs-align_center">
          <div className="cs-col_6">
            <div className="cs-about_img cs-style1">
              <img src="/images/about_img_1.jpeg" alt="About" />
              <div className="cs-circle_shape"></div>
            </div>
          </div>
          <div className="cs-col_6">
            <div className="cs-about cs-style1">
              <img
                src="/images/shapes/shape_6.svg"
                alt="Shape"
                className="cs-shape_position_1"
              />
              <img
                src="/images/shapes/shape_7.svg"
                alt="Shape"
                className="cs-shape_position_2"
              />
              <img
                src="/images/shapes/shape_8.svg"
                alt="Shape"
                className="cs-shape_position_3"
              />
              <h2>{t('about_1_title')}</h2>
              <p>{t('about_1_subtitle_text_1')}</p>
              <p>{t('about_1_subtitle_text_2')}</p>
              <p>{t('about_1_subtitle_text_3')}</p>
              <p>
                - {t('about_1_subtitle_text_4')}
                <br />- {t('about_1_subtitle_text_5')} <br />-{' '}
                {t('about_1_subtitle_text_6')}
              </p>
            </div>
          </div>
        </div>
        <Spacing lg="135" md="70" />
      </div>
      <div className="cs-gradient_separetor"></div>
      <div className="cs-container">
        <Spacing lg="100" md="70" />
        <div className="cs-row">
          <div className="cs-col_6">
            <div className="cs-about cs-style2">
              <img
                src="/images/shapes/shape_6.svg"
                alt="Shape"
                className="cs-shape_position_1"
              />
              <h2>{t('about_2_title')}</h2>
              <p>{parse(t('about_2_subtitle_text'))}</p>
            </div>
          </div>
          <div className="cs-col_6">
            <div className="cs-about_img cs-style2">
              <img src="/images/about_img_2.jpeg" alt="About" />
              <div className="cs-circle_shape"></div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="100" md="70" />
      <div className="cs-container">
        <div className="cs-section_heading cs-style2">
          <h2 className="cs-section_title">
            {t('iconbox_heading')}
            <img src="/images/shapes/shape_5.svg" alt="Shape" />
          </h2>
        </div>
        <Spacing lg="90" md="50" />
        <div className="cs-row">
          <div className="cs-col_4">
            <div className="cs-iconbox cs-style2">
              <div className="cs-iconbox_icon">
                <img src="/images/icons/icon1.svg" alt="Icon" />
              </div>
              <h2 className="cs-iconbox_title">{t('iconbox_1_title')}</h2>
              <div className="cs-iconbox_title_highlite">
                <img src="/images/shapes/shape_4.svg" alt="Shape" />
              </div>
              <div className="cs-iconbox_subtitle">
                <p>{t('iconbox_1_subtitle_1')}</p>
                <p>{t('iconbox_1_subtitle_2')}</p>
              </div>
            </div>
            <Spacing lg="0" md="30" />
          </div>
          <div className="cs-col_4">
            <div className="cs-iconbox cs-style2">
              <div className="cs-iconbox_icon">
                <img src="/images/icons/icon2.svg" alt="Icon" />
              </div>
              <h2 className="cs-iconbox_title">{t('iconbox_2_title')}</h2>
              <div className="cs-iconbox_title_highlite">
                <img src="/images/shapes/shape_4.svg" alt="Shape" />
              </div>
              <div className="cs-iconbox_subtitle">
                <p>{t('iconbox_2_subtitle_1')}</p>
              </div>
            </div>
            <Spacing lg="0" md="30" />
          </div>
          <div className="cs-col_4">
            <div className="cs-iconbox cs-style2">
              <div className="cs-iconbox_icon">
                <img src="/images/icons/icon3.svg" alt="Icon" />
              </div>
              <h2 className="cs-iconbox_title">{t('iconbox_3_title')} </h2>
              <div className="cs-iconbox_title_highlite">
                <img src="/images/shapes/shape_4.svg" alt="Shape" />
              </div>
              <div className="cs-iconbox_subtitle">
                <p>{t('iconbox_3_subtitle_1')} </p>
                <p>{t('iconbox_3_subtitle_2')}</p>
                <p>{t('iconbox_3_subtitle_3')}</p>
              </div>
            </div>
            <Spacing lg="0" md="30" />
          </div>
        </div>
        <Spacing lg="95" md="30" />
        <div className="container cs-text_center">
          <Link
            to="/register"
            className="cs-btn cs-style1"
            style={{ maxWidth: '192px', width: '100%', borderRadius: '30px' }}
          >
            {t('iconbox_btn_text')}
          </Link>
        </div>
      </div>
      <Spacing lg="70" md="70" />
      <section
        className="cs-shape_wram_1 cs-bg"
        style={{
          backgroundImage: 'url(/images/feature_bg.svg)',
          backgroundColor: '#f7f8f9',
        }}
      >
        <div className="cs-shape cs-position_1">
          <img src="/images/shapes/shape_2.svg" alt="Shape" />
        </div>
        <div className="cs-shape cs-position_2">
          <img src="/images/shapes/shape_3.svg" alt="Shape" />
        </div>
        <Spacing lg="90" md="70" />
        <div className="cs-container">
          <div className="cs-section_heading cs-style1">
            <h2 className="cs-section_title">{t('feature_heading')}</h2>
            <div className="cs-section_subtitle">
              {parse(t('feature_subheading'))}
            </div>
          </div>
        </div>
        <Spacing lg="150" md="70" />
        <div className="cs-container">
          <div className="cs-row cs-align_center">
            <div className="cs-col_6">
              <div className="cs-iconboxes_1 cs-type1">
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/1.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#3BA4DF' }}
                    >
                      <Icon icon="ph:envelope-simple" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_1_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_1_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/2.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#3BA4DF' }}
                    >
                      <Icon icon="ri:edit-line" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_2_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_2_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/3.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#3BA4DF' }}
                    >
                      <Icon icon="material-symbols:grid-view-outline" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_3_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_3_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cs-col_6"></div>
          </div>
        </div>
        <Spacing lg="140" md="70" />
        <div className="cs-container">
          <div className="cs-row cs-align_center">
            <div className="cs-col_6"></div>
            <div className="cs-col_6">
              <div className="cs-iconboxes_1">
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/4.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#4680C2' }}
                    >
                      <Icon icon="ph:envelope-simple" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_4_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_4_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/5.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#4680C2' }}
                    >
                      <Icon icon="ri:edit-line" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_5_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_5_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-iconbox cs-style1">
                  <div className="cs-iconbox_img">
                    <img src="/images/feature/6.png" alt="Iconbox" />
                  </div>
                  <div className="cs-iconbox_in">
                    <div
                      className="cs-iconbox_icon"
                      style={{ backgroundColor: '#4680C2' }}
                    >
                      <Icon icon="material-symbols:grid-view-outline" />
                    </div>
                    <div className="cs-iconbox_right">
                      <h2 className="cs-iconbox_title">
                        {t('feature_6_title')}
                      </h2>
                      <div className="cs-iconbox_subtitle">
                        {t('feature_6_subtitle')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="100" md="70" />
      </section>
      <Contact />
    </>
  );
}
