import React from "react";
import { Link } from "react-router-dom";
import { pageTitle } from "../helper";
import { useTranslation } from "react-i18next";

export default function Register() {
  const { t } = useTranslation();
  pageTitle("Register");
  return (
    <div className="cs-login">
      <div
        className="cs-login_left"
        style={{ backgroundImage: "url(/images/register.jpg)" }}
      ></div>
      <div className="cs-login_right">
        <div className="cs-login_info cs-primary_font">
          <div className="cs-text_center">
            <Link to="/">
              <img src="/images/icons/icon4.svg" alt="Icon" />
            </Link>
            <h2>{t("login_title")}</h2>
            <h3>{t("register_subtitle")}</h3>
          </div>
          <div className="cs-login_info_in">
            <p>{t("login_text_1")}</p>
            <a
              href="https://creator.gorudo.io/auth/register"
              className="cs-btn cs-style1"
              style={{ width: "100%", borderRadius: "30px" }}
            >
              {t("login_btn_1")}
            </a>
            <div className="cs-or">
              <span>or</span>
            </div>
            <p>{t("login_text_2")}</p>
            <a
              href="https://member.gorudo.io/auth/register"
              className="cs-btn cs-style1"
              style={{ width: "100%", borderRadius: "30px" }}
            >
              {t("login_btn_2")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
