import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../components/Spacing';
import { pageTitle } from '../helper';
import { useTranslation } from 'react-i18next';
import AccordionCreator from '../components/Accordion/AccordionCreator';
import AccordionMember from '../components/Accordion/AccordionMember';

export default function Faqs() {
  pageTitle('FAQs');
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="75" md="75" />
      <div
        className="cs-page_head cs-bg"
        style={{ backgroundImage: 'url(/images/faq_bg.jpg)' }}
      >
        <div className="cs-container">
          <div className="cs-page_head_in">
            {/* <h1 className="cs-page_title">{t('payments_heading')}</h1> */}
          </div>
        </div>
      </div>
      <div className="cs-container">
        <Spacing lg="30" md="30" />
        <div className="cs-section_heading cs-style3">
          <div className="cs-text_center">
            <Link to="/">
              <img src="/images/icons/icon4.svg" alt="Icon" />
            </Link>
          </div>
          <Spacing lg="15" md="15" />
          <h2 className="cs-section_title">{t('faq_heading')} </h2>
        </div>
        <Spacing lg="60" md="40" />
        <h2 style={{ fontSize: '26px', marginBottom: '15px' }}>
          {t('creators_text')}
        </h2>
        <AccordionCreator />
        <h2
          style={{ fontSize: '26px', marginBottom: '15px', marginTop: '50px' }}
        >
          {t('members_text')}
        </h2>
        <AccordionMember />
        <Spacing lg="100" md="70" />
      </div>
    </>
  );
}
