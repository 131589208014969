import React from "react";

export default function TermsOfUseTC() {
  return (
    <div className="cs-general_content">
      <b>使用條款</b> <br />
      <b>最近更新：2023年6月</b> <br />
      <b>歡迎使用Gorudo！</b> <br />
      <p>
        Gorudo是一個會員平台，使創作者能夠通過分享他們的金融知識和想法而獲得會員的支付。我們希望讓創作者能夠輕鬆地與會員即時分享他們的個人知識和想法。
      </p>
      <p>
        這些是Gorudo的使用條款，適用於Gorudo平台的所有用戶。
        “我們”，“我們的”或“我們”指的是Gorudo公司及其子公司。“Gorudo”指的是本平台及我們提供的服務。使用Gorudo即表示您同意這些條款以及我們張貼的其他政策。請仔細閱讀並讓我們知道如果您有任何問題。有關我們的數據使用方法的信息，請參閱我們的隱私政策，其中包括我們的Cookie政策。我們可以根據這些政策收集和使用您的信息。
      </p>
      <p>
        <b>您的帳戶</b>
        <br />
        當您創建帳戶時，您必須提供準確的信息，真誠地提供，如果有變化，您同意及時更新您的信息。要創建帳戶，您必須年滿13歲。要以創作者的身份加入會員，或者作為創作者提供會員，您必須年滿18歲或獲得您的父母許可。您對任何人登錄您的帳戶的活動負責，以及您帳戶的安全。如果您認為您的帳戶已被擄獲，請立即與我們聯繫。
      </p>
      <p>
        <b>虐待行為</b> <br />
        您對您帳戶上的所有活動負責。如果您違反我們的政策，我們可能會終止您的帳戶。不要進行任何非法、對他人的虐待或濫用我們的網站的技術方式。如果您是在Gorudo上籌集資金的創作者，我們可能會對您使用這些資金的行為負責。因此，我們還會查看您在我們平台之外的會員行為。
      </p>
      <p>
        <b>創作者</b>
        <br />
        創作者是在Gorudo上創建創作者帳戶以與從Gorudo購買會員的Gorudo會員互動的人。如果您是創作者，那麼您應該仔細閱讀以下涉及付款、費用、稅收和限制的詳細信息。
      </p>
      <p>
        <b>會員</b> <br />
        要成為創作者，只需創建創作者帳戶並創建您的會員資格。當您加入Gorudo時，您成為Gorudo社區的一部分。您可以使用Gorudo提供的創作者工具，分享您的個人知識和想法，並從您的頁面獲得定期收入。在Gorudo上，您可以為會員提供他們想要的獨特福利，例如額外的訪問權限、獨家權利和有趣的體驗。作為回報，您將獲得來自會員的忠誠支持，以及從Gorudo購買的會員支付。
      </p>
      <p>
        <b>付款</b>
        <br />
        作為創作者，您將您的會員資格在Gorudo上提供，我們以訂閱的方式向您的會員提供會員資格。我們還處理付款問題，例如欺詐、退款和解決付款爭議。我們會儘快提供您資金的使用權限，但是您有時可能會遇到資金使用權限的延遲。我們也可能因違反我們的政策或符合稅務報告要求而封鎖或保留付款。當付款被延遲或被封鎖時，我們會盡快告知您原因。如果您對付款封鎖有疑問，請聯繫我們。為了保護創作者，如果我們認為會員付款存在欺詐行為，我們可能會封鎖會員的付款。有時，像退款這樣的活動會使您的帳戶餘額變為負值。如果您的帳戶餘額變為負值，我們保留從未來的付款中收回這些資金的權利。
      </p>
      <p>
        <b>費用</b>
        <br />
        作為創作者，在Gorudo上使用您的會員資格有兩個費用。第一個是平台費用，根據您所選擇的服務級別（Gorudo計劃）而有所不同。第二個是付款處理費用，這取決於創作者選擇的貨幣。
        <ul>
          <li>
            結算貨幣為美元，每筆成功交易的支付處理費為4.4% +
            0.3美元。當會員使用其他貨幣進行付款時，將產生2%的貨幣匯率費用。例如，歐元將按照中間市場匯率轉換為美元，匯率換算費用為2%（不是支付費用）。
            您的銀行/信用卡提供商可能會收取額外費用。
          </li>
        </ul>
        根據您的會員所在地，有些銀行可能會對會員的會員訂閱收取外幣交易費。Gorudo無法控制此費用，但通常約為3.0％。
      </p>
      <p>
        <b>稅收</b>
        <br />
        您對根據您的會員資格收到的所有款項負責，包括必須支付的稅款。您應該咨詢您的稅務顧問以確定您應該如何報告這些支付。
      </p>
      <p>
        <b>財務建議</b>
        <br />
        身為創作者，您有責任確保內容和創作不構成出售的要約、購買的邀約或購買任何證券的要約，亦不應被視為購買或出售的要約或邀約，除非您擁有相關的財務牌照從事此類活動。
      </p>
      <p>
        <b>限制</b>
        <br />
        我們不允許違反我們政策的內容和創作。總而言之，我們不允許：
        <ul>
          <li>非法的創作或福利。</li>
          <li>侮辱他人的內容或創作。</li>
          <li>
            使用他人知識產權的內容或創作，除非您有書面許可使用它，或您的使用受到合理使用的保護。
          </li>
          <li>包含真實人物從事性行為的內容或創作。</li>
          <li>涉及根據機會進行的抽獎或獎品的創作。</li>
        </ul>
        如果您的粉絲中包括18歲以下的人，請提醒他們需要獲得許可才能加入您的會員資格，而13歲以下的人則不能使用Gorudo。我們不必允許任何特定的人或人群成為您的會員。{" "}
        <br />
        身為創作者，您還負責保護會員的數據安全。您可以在我們的數據處理協議中看到所需的內容。帳戶與您的內容相關聯，不能被出售或轉讓給其他創作者使用。{" "}
        <br />
        如果創作者違反Gorudo政策中概述的限制，Gorudo可能會採取行動，例如限制或刪除創作者對平台的訪問權限，暫停或終止創作者的帳戶，或刪除創作者的內容。如果必要，Gorudo也可能對創作者採取法律行動。{" "}
        <br />
        此外，如果創作者違反數據處理協議或未能保護會員的數據安全，Gorudo可能會終止創作者的帳戶並在必要時採取法律行動。{" "}
        <br />
        對於創作者來說，遵守Gorudo概述的限制和政策，以確保對所有會員的安全和積極環境非常重要。
      </p>
      <p>
        <b>會員</b>
        <br />
        會員是從Gorudo購買會員資格以支持其喜愛的創作者內容的人。通過在Gorudo上付費訂閱會員，會員可以直接從支持的創作者那裡獲取內容，使用Gorudo提供的工具，並通過我們的平台有機會與其他創作者互動。{" "}
        <br />
        作為會員，您加入Gorudo是為了成為一個激動人心的運動的一部分，支持創作者的會員資格，同時獲得特殊福利，可能包括與創作者的互動、獨家權限和豐富的體驗。作為交換，您需要按照訂閱方式向Gorudo支付會員費用，以訪問和支持您喜歡的創作者。{" "}
        <br />
        每個會員訂閱的時間和金額取決於您所選擇的會員協議類型和您所支持的創作者。您可以在註冊時查看詳細信息，也可以在每次成功付款後的收據中查看。您可以在訂閱頁面上查看所有活躍的會員訂閱和帳單歷史記錄。
        <br />
        要成為會員，只需創建一個會員帳戶，添加您喜好的付款方式，並在Gorudo上加入會員計劃，開始支持您的創作者。所有會員計劃都是按月訂閱，您需要每月支付一定的費用。{" "}
        <br />
        您可以隨時取消會員付款。終止或降低會員的支持等級將在下一個付款期生效（而不是當前期）。如果您提高年度會員費用的付款等級，則將在當前付款期生效。
        <br />
        當您取消會員訂閱、付款方式失敗、創作者阻止您或創作者刪除其帳戶時，您的會員資格將被終止。我們不必允許您成為任何特定創作者的會員。{" "}
        <br />
        創作者的會員資格各不相同，我們對其品質和具體提供的內容僅有有限的控制。我們會試圖篩選欺詐性的創作者頁面，但無法保證創作者的身份或其所作任何聲明的有效性。我們感謝您幫助報告可疑的創作者頁面，以便我們保持Gorudo的安全性。{" "}
        <br />
        根據您的位置，某些銀行可能會對您的會員訂閱收取外幣交易費。Gorudo無法控制此費用，但通常約為總費用的3％。請聯繫您的銀行獲取更多信息。
        <br />
        <b>退款。</b>{" "}
        我們的政策是不予退款，但我們會在我們的唯一裁量權下允許一些例外情況下進行退款
        <br />
        <b>Gorudo的信息和內容的重要免責聲明</b>
        <br />
        請勿根據本網站上的任何內容做出任何投資決策。
        <br />
        作為會員，您同意本網站和服務中的觀點是創作者自己的意見。本網站和服務可能會不時包含有關其他網站或服務中提到的證券的意見，並且這些意見可能與使用本網站或服務的其他部分所得到的意見不同。證券交易（包括但不限於股票、期權、ETF和債券）涉及風險和波動性。過去的表現不一定預示著未來的表現。
      </p>
      <p>
        <b>Gorudo的角色</b>
        <br />
        我們積極審查Gorudo上的某些頁面和帖子，以確保創作者遵守我們的政策。我們還調查潛在違規行為的報告。這些調查可能需要一段時間才能解決，並可能包括查看通過Gorudo收到的資金支持了什麼。
        <br />
        在大多數情況下，我們將與創作者合作解決任何潛在的違規行為，並允許創作者繼續使用Gorudo。終止賬戶不是我們輕易採取的行動，只有在極端情況下才會執行。
        <br />
        Gorudo保留將創作者或會員從特定會員資格中移除的權利，但不會自動將創作者或會員從平台中移除，除非存在獨立的基礎來將其從平台中移除。
        如果您發現我們的政策存在潛在違規行為，請告知我們。您可以通過
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>.了解如何報告。
        <br />
        A作為一家總部位於香港並在其他國家開展業務的全球公司，我們必須遵守經濟制裁和貿易限制，包括由美國財政部外國資產控制辦公室（"OFAC"）實施的製裁。這意味著Gorudo不能參與涉及那些由OFAC等機構確定的指定人員、地點或物品的交易。不限前述，通過使用本服務，您聲明和保證：（a）您不位於、不是常駐於或不是根據任何受到全面美國政府禁運的管轄區法律組織的
        ("受禁管轄區")；（b）您不受由美國政府、任何其他政府或聯合國（統稱為"制裁"）管理的任何制裁的約束；（c）您不直接或間接地由任何受制裁的人、位於、常駐於或根據任何受禁管轄區的法律組織控制；（d）您的任何高管、經理、董事、股東或授權代表均不受制裁的約束，也不位於、常駐於或根據任何受禁管轄區的法律組織控制，也不直接或間接地由任何受制裁的人或位於、常駐於或根據任何受禁管轄區的法律組織控制。您還承諾在本協議整個期間上述情況都是真實的。如果您在美國境外訪問或使用本服務，則您應負責確保您在該國家、地區或司法管轄區訪問和使用本服務不違反任何適用法律。
        <br />
        我們一直在不斷測試新功能，旨在使Gorudo變得更好。我們可能會添加或刪除功能，並經常與我們社區的隨機子集進行功能測試。如果我們認為某個功能與這些條款有顯著不同之處，那麼我們會在測試中解釋這些差異。
        <br />
        在獲得您的許可後，我們可能會授權其他網站或服務驗證您的Gorudo賬戶信息或代表您執行操作。當您將Gorudo賬戶連接到這些其他網站或服務時，我們會要求您獲得許可。您可以在我們的隱私政策中了解更多信息。
      </p>
      <p>
        <b>賬戶刪除</b>
        <br />
        您可以隨時通過發送電子郵件至support@gorudo.io永久刪除您的賬戶。我們可能會要求提供一些個人信息來驗證該賬戶。我們可以隨時自行終止或暫停您的賬戶。我們也可以自行取消任何會員訂閱並刪除任何說明、帖子或福利。您不得因為我們暫停或終止他人的賬戶而對我們提出索賠，並且您同意不會提出此類索賠。如果您試圖提出此類索賠，則應對造成的損害負責，包括律師費和費用。即使您不再擁有賬戶，這些條款仍然有效。
      </p>
      <p>
        <b>您的內容</b>
        <br />
        您擁有您在Gorudo上提供的所有創作的完全所有權，但我們需要您的許可證來有效運營Gorudo。通過在Gorudo上發布創作，您授予我們免版稅、永久、不可撤銷、非排他性、可轉讓的全球許可證，以使用、複製、分發、執行、公開展示或準備您的內容的衍生作品。此許可證的目的嚴格限於允許我們向您的會員提供和促銷會員資格。我們永遠不會試圖竊取您的內容或以剝削性的方式使用它們。您不得發布侵犯他人知識產權或專有權利的內容。我們可能會要求您對通過Gorudo資助的內容中描述的合作者進行同意驗證。會員不得以創作者未授權的方式使用創作者發布的內容。
      </p>
      <p>
        <b>您的第三方應用</b>
        <br />
        為了使一些Gorudo功能運作，您可以授權Gorudo訪問您的第三方賬戶，例如Google、YouTube、Facebook、Instagram、Twitter和Twitch等。每次連接您的第三方賬戶時，該第三方賬戶將呈現一個頁面，描述Gorudo可以訪問的信息。您可以隨時使用各自第三方的安全設置頁面撤銷Gorudo對這些賬戶的訪問權限。以下是每個服務的鏈接：
        <br /> <br />
        <table>
          <tbody>
            <tr>
              <td>Google</td>
              <td>
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>YouTube</td>
              <td>
                <a href="https://www.youtube.com/t/terms" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://policies.google.com/terms" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>
                <a href="https://www.facebook.com/terms.php" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://www.facebook.com/policy.php" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Instagram</td>
              <td>
                <a
                  href="https://help.instagram.com/581066165581870"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </td>
              <td>
                <a
                  href="https://help.instagram.com/402411646841720"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td>
                <a href="https://twitter.com/en/tos" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://twitter.com/en/privacy" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Twitch</td>
              <td>
                <a
                  href="https://www.twitch.tv/p/legal/terms-of-service/"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </td>
              <td>
                <a
                  href="https://www.twitch.tv/p/legal/privacy-notice/"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </p>
      <p>
        <b>Gorudo的創作</b>
        <br />
        我們的創作受版權、商標和商業秘密法的保護。我們的創作包括網站上的文本、我們的標誌和我們的代碼庫等。我們授予您使用我們的標誌和其他版權或商標來促銷您的Gorudo頁面的許可證。除非我們以書面形式給予您許可，否則您不得以其他方式使用、複製、分發、執行、公開展示或準備我們的創作的衍生作品。如有任何疑問，請諮詢我們。
      </p>
      <p>
        <b>賠償</b>
        <br />
        您將賠償我們因這些條款或與您使用Gorudo相關的所有損失和責任，包括法律費用。我們保留對此條款涵蓋的索賠的獨家控制權。如果我們使用此權利，則您將協助我們辯護。根據本條款，您賠償的義務也適用於我們的子公司、附屬公司、高管、董事、員工、代理人和第三方服務提供商。
      </p>
      <p>
        <b>免責聲明</b>
        <br />
        <b>
          Gorudo按“現狀”提供，不提供任何形式的保證。任何適銷性、特定用途適用性、非侵權和其他保證均在法律允許的最大範圍內排除。本條款下的保證免責聲明也適用於我們的子公司、附屬公司和第三方服務提供商。
        </b>
      </p>
      <p>
        <b>責任限制</b>
        <br />
        <b>
          在法律允許的範圍內，我們對於這些條款或您使用或嘗試使用Gorudo所產生的任何附帶、後果或懲罰性損害不承擔責任。在法律允許的範圍內，我們對於損害的責任僅限於我們通過您使用Gorudo所賺取的金額。我們特別不對未實現的福利和因衝突合同協議而引起的損失承擔責任。在本條款中，“我們”和“我們的”包括我們的子公司、附屬公司、高管、董事、員工、代理人和第三方服務提供商。
        </b>
      </p>
      <p>
        <b>爭議解決</b>
        <br />
        我們鼓勵您聯繫我們解決任何問題。如果因這些條款或與您使用Gorudo相關的任何問題引起爭議，則該爭議將根據香港法律解決。雙方同意香港法院具有獨占的管轄權和地點，以解決任何這類爭議。香港法律，排除其法律衝突規定，管理這些條款、所有其他Gorudo政策以及您與Gorudo之間產生的任何爭議。
      </p>
      <p>
        <b>App條款</b>
        <br />
        您需要提供移動設備、無線服務計劃、軟件、互聯網連接和/或其他設備或服務來下載、安裝和使用App。我們無法保證App可以在任何特定設備或任何特定服務計劃上訪問和使用。我們不保證App或服務將在任何特定的地理位置可用。
        <br />
        以下條款和條件僅適用於您從Apple App
        Store使用App的情況：在這些條款的其他條款和條件比本段的條款和條件不那麼嚴格或與之衝突的情況下，本段的更嚴格或衝突的條款和條件適用，但僅限於您從Apple
        App
        Store使用App的情況。您承認並同意這些條款僅適用於您和公司之間的關係，而不適用於Apple，Apple對App或其內容沒有任何責任。您使用App必須遵守App
        Store的適用使用條款。您承認Apple沒有任何義務提供任何與App有關的維護和支持服務。如果App未能符合任何適用的保證，您可以通知Apple，並且Apple將向您退還App的購買價格（如果有）。在適用法律的最大範圍內，Apple對App沒有任何其他保證義務，任何其他索賠、損失、責任、損害賠償、費用或支出歸因於未能符合任何保證，將僅由這些條款進行規定。您承認Apple不負責處理您或任何第三方與App或您擁有和/或使用App有關的任何索賠，包括但不限於：（a）產品責任索賠，（b）任何聲稱App未能符合任何適用法律或法規要求的索賠，以及（c）根據消費者保護或類似法律產生的索賠。您承認，如果有任何第三方聲稱App或您擁有和使用該App侵犯了該第三方的知識產權，公司而非Apple將對調查、辯護、解決和履行任何此類知識產權侵權索賠負全部責任，只要這符合這些條款的要求。使用App時，您必須遵守適用的第三方協議條款。您承認並同意，Apple及其子公司是這些條款與您使用App相關的第三方受益人，並且在您接受這些條款後，Apple將有權（並將被視為已接受該權利）以第三方受益人的身份對您執行這些條款。{" "}
        <br />
      </p>
      <p>
        <b>其他事項</b>
        <br />
        這些條款及任何引用的政策是您和我們之間的完整協議，並取代所有先前的協議。如果這些條款的任何條款被認為無法執行，則該條款被修改為必要的程度以執行它。如果某項規定無法修改，則該規定將從這些條款中刪除，而所有其他規定仍然有效。如果任何一方未能執行這些條款所提供的權利，則不會放棄將來執行任何權利的能力。我們有時會對這些條款和政策進行更改。如果我們進行了對您權利不利的實質性更改，我們會在更改生效之前通知您。繼續使用Gorudo表示您接受新的條款或政策。這些條款是我們之間的協議。如果您使用輔助工具，並有問題或疑慮，請通過
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>
        與我們聯繫。數據處理
      </p>
      <p>
        <div style={{ textAlign: "center" }}>
          <b>協議 - 創作者隱私承諾</b>
        </div>
        Gorudo為平台上的創作者（“創作者”）提供資源，使其可以直接與最忠實的粉絲聯繫並將其轉化為付費會員（“會員”），從而開展會員制業務。
        <br />
        為了促進Gorudo與創作者之間的直接聯繫，並實現會員獎勵和義務的履行，Gorudo向創作者提供會員的個人數據（“會員數據”）。創作者隨後處理會員數據，以便在Gorudo上為會員提供所有產品或服務，作為該創作者的會員制業務的一部分（統稱為“會員服務”）。為確保創作者尊重會員處理會員數據時的隱私權，Gorudo要求所有創作者同意此數據處理協議（“隱私承諾”）。{" "}
        <br />
        此隱私承諾是Gorudo與創作者之間的協議，自Gorudo帳戶建立之時起生效，並僅適用於Gorudo收集並提供給創作者的會員數據，目的是在Gorudo上經營會員制業務。{" "}
        <br />
        此隱私承諾是Gorudo使用條款和隱私政策的一部分，將概述創作者在與Gorudo的關係期間及之後處理會員數據的某些要求。
      </p>
      <p>
        <b>I. 定義</b>
        <br />
        <ul>
          <li>
            <b>
              “數據保護法規”指任何相關司法管轄區域中可能存在的有關隱私和個人數據處理的適用法律，包括（如適用）監管當局發布的指導和實踐準則。數據保護法規包括但不限於歐洲指令95/46/EC和2002/58/EC（由指令2009/136/EC修改）以及任何實施或根據它們制定、修改、取代、重新制定或整合它們的法律和/或法規，包括《通用數據保護條例》（法規（EU）2016/279）。
            </b>
          </li>
          <li>
            <b>
              “良好行業慣例”指以與提供有關服務的熟練和經驗豐富的人士相同的技能、專業知識和判斷力，以誠實信用履行其合同義務、盡力避免在任何合理的注意義務下產生責任的人士所期望的同樣質量的設施和資源，並遵守數據保護法規。
            </b>
          </li>
          <li>
            “數據控制者”、“數據處理者”、“子處理者”、“數據主體”、“個人數據”、“處理”和“適當的技術和組織措施”應根據Directive
            95/46/EC或其他適用的數據保護法規在相關司法管轄區域中進行解釋。
          </li>
        </ul>
      </p>
      <p>
        <b>II. 範圍</b> 。雙方同意，Gorudo 是數據控制者，而 Creator
        則是數據處理者，就 Creator
        在提供會員服務過程中處理的會員數據而言。數據處理的主題、處理的個人數據類型以及數據主體類別將由，並/或僅限於，執行會員服務所必要的內容。這個隱私承諾所涉及的處理將在
        Creator 離開 Gorudo
        平台時進行。此隱私承諾所涉及的個人數據的主題、持續時間、性質和目的，以及個人數據的類型和類別涵蓋如下：
        <ul>
          <li>數據處理的主題是會員數據。</li>
          <li>處理的持續時間為 Creator 持有會員數據的時間。</li>
          <li>
            此隱私承諾下的處理的性質和目的僅限於 Creator 履行對會員的會員服務。
          </li>
          <li>
            根據本協議涵蓋的個人數據類型是聯繫信息，包括但不限於用戶名、電子郵件地址、送貨地址和承諾金額。
          </li>
          <li>數據主體的類別是在 Gorudo 上註冊會員的網站用戶。</li>
        </ul>
      </p>
      <p>
        <b>III. 數據保護。</b> Creator 應遵守以下要求：
        <ul>
          <li>
            <b>按照指示進行處理。</b> Creator 將僅按照 Gorudo
            《使用條款》、隱私政策和本隱私承諾進行會員數據處理，並僅遵守數據保護法規。處理的性質和目的應限於執行此類指示所必要的內容，而不是為
            Creator 自身的目的，或為任何其他目的，除非法律要求。如果 Creator
            根據法律要求為任何其他目的處理個人數據，Creator 將在處理之前通知
            Gorudo，除非法律禁止這樣做。
          </li>
          <li>
            <b>處理的範圍。</b> Creator
            將僅以必要的範圍和方式處理個人數據，以提供會員服務
          </li>
          <li>
            <b>適當的技術和組織措施。</b>Creator
            將實施和維護適當的技術和組織措施，旨在保護個人數據免受未經授權或非法處理以及意外的損失、破壞、損壞、盜竊、更改或披露。這些措施應適合任何未經授權或非法處理、意外的損失、破壞或盜竊所可能引起的損害，並考慮到所需保護的個人數據的性質，並至少符合數據保護法規和良好行業慣例。
          </li>
          <li>
            <b>轉移給第三方。</b> Creator 將不會未經 Gorudo
            的事先同意，向任何第三方（包括任何聯屬公司、集團公司或分包商）提供存取或轉移任何個人數據。Creator
            還必須確保這些第三方及其員工或代理人具有可靠性和能力，他們可能會存取在提供會員服務過程中處理的個人數據，並必須在與該等第三方的任何合同中包含與本隱私承諾和《使用條款》中保護會員相等的條款，以及適用的數據保護法規所要求的條款。
          </li>
          <li>
            <b>Creator 工作人員可靠性和能力的確認。</b> Creator
            將採取合理措施確保任何有權存取會員數據的 Creator
            工作人員的可靠性和能力。Creator 將確保所有需要存取個人數據的 Creator
            工作人員均瞭解個人數據的機密性質，並遵守本隱私承諾所列明的義務。
          </li>
          <li>
            <b>數據保護法規的認可和協助。</b>Creator 將採取一切合理措施協助
            Gorudo 遵守適用的數據保護法規。例如，Creator 將及時以書面形式通知
            Gorudo，如果收到以下信息：（i）任何有關個人數據的數據主體請求；或（ii）任何有關會員對數據保護法規的義務的投訴、通訊或請求。
          </li>
          <li>
            <b>完成會員服務後的物品銷毀或歸還。</b>Creator
            不會保留任何個人數據，超過提供會員服務所需的時間。在會員服務結束或會員的請求下，Creator
            將安全銷毀或歸還（由會員選擇）個人數據給會員。
          </li>
          <li>
            <b>損失或安全漏洞。</b> 如果 Creator 發現 Creator
            在提供會員服務過程中處理的會員數據出現任何意外、未經授權或非法的破壞、損失、更改或披露，Creator
            將採取以下措施：
            <ul>
              <li>
                <b>通知 Gorudo。</b> Creator 必須立即且不延遲地通知 Gorudo，並向
                Gorudo
                提供：有關損失或安全漏洞的詳細說明；是哪些數據成為損失或安全漏洞的主題；如已知，每個受影響的人的身份；Creator
                採取或將採取的措施，以尽快减轻和修复此安全漏洞（以及此類信息的定期更新和
                Gorudo 可以合理要求的任何其他信息）；以及
              </li>
              <li>
                <b>迅速調查此事。</b> Creator
                將立即採取措施，以自身的費用調查損失或安全漏洞，並确定、防止和减轻損失或安全漏洞的影響，並實施適當的恢復措施以修復損失或安全漏洞。
              </li>
            </ul>
          </li>
          <li>
            <b>9. 遵守數據保護法規。</b> Creator 應始終遵守並協助 Gorudo
            遵守其適用的數據保護法規下的義務。Creator 應提供 Gorudo
            要求的合理信息，以證明其遵守本隱私承諾所列明的義務。如果 Creator
            認為 Gorudo
            給出的個人數據處理指示違反了任何國家的數據隱私法規，Creator
            將立即通知 Gorudo。
          </li>
        </ul>
      </p>
    </div>
  );
}
