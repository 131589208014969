import React from 'react';

export default function PrivacyEN() {
  return (
    <div className="cs-general_content">
      <b>Privacy Policy</b> <br />
      <b>Latest update: June 2023</b> <br />
      <p>
        The Privacy Policy applies to Members, Creators and all users of our
        platform, and is part of our Terms of Use. <br />
        Gorudo is a global company. By using our platform, you agree that your
        personal information that you provide directly to us, or that we collect
        through your use of the platform, may be transferred to and stored in
        the Hong Kong and handled as described in this Policy.
      </p>
      <p>
        <b>Information You Provide Through Your Account</b>
        <br />
        This is information that you provide to us through text fields, such as
        your name, payment information and benefits. The information we collect
        differs depending on whether you create an account, become a Member or
        become a Creator.
        <ul>
          <li>First and Last Name</li>
          <li>Email Address</li>
          <li>Display Name</li>
          <li>Password</li>
          <li>Country of Residence</li>
        </ul>
        You may also sign up using a Facebook or Google account. We will ask for
        permission to access basic information from your Facebook or Google
        account, such as your name, email and profile picture. You can choose to
        stop sharing that information with us at any time by going to Facebook
        or Google to remove Gorudo’s access to that account and your ability to
        log in. <br />
        You also have the option to add more information to your public profile,
        such as a description section, social media links and communication
        tools links, which we store along with any comments or messages you
        submit on Gorudo.
      </p>
      <p>
        <b>Members</b> <br />A member is someone who joins Gorudo’s membership
        platform to support a Creator’s membership content. As a Member you must
        provide your payment information to our payment processors. You can see
        the Privacy Policy for these payment processors on the{' '}
        <a href="https://stripe.com/us/privacy/">Stripe</a> site. Gorudo does
        not receive your full card number at this time. Payment processors
        provide us with a token that represents your account, your card’s
        expiration date, card type and the last four digits of your card number.
        If you are required to provide your name and email address to the
        payment processor then they also provide us with that information.{' '}
        <br />
        We collect and process information about the Creators you support, the
        level at which you support them, what benefits you receive and how often
        you support them.
      </p>
      <p>
        <b>Creators</b> <br />
        A Creator is someone who creates and provides content for their Members
        through Gorudo platform. To become a Creator, you must create a page
        that describes yourself and any benefits you are offering. To receive
        payouts you have to create an account with our payment partner: <br />
        Stripe. We may also require your bank account information to process
        payouts. You must also provide us with additional information for tax
        purposes. Depending on your location you may have to fill out a form
        with some combination of your:
        <ul>
          <li>First and Last Name</li>
          <li>Address</li>
          <li>Social Security Number or Employer Identification Number</li>
          <li>Country of Citizenship</li>
          <li>Foreign Tax Identification Number</li>
          <li>Date of Birth</li>
          <li>Bank Account Information for Payouts</li>
          <li>Phone Number</li>
        </ul>
      </p>
      <p>
        <b>Additional Information We Collect</b>
        <br />
        <b>Automatically Collected Information</b> <br />
        We collect information automatically as you navigate the site or through
        our third-party analytics providers. We may store usage information such
        as the type of device you use to access Gorudo, your operating system,
        browser type, IP address, device ID, the pages you visit or request,
        links clicked, referring sites, user interactions and your search terms.
        We also derive your location from your self-disclosed country, your IP
        address and your payment card.
      </p>
      <p>
        <b>User Surveys</b>
        <br />
        From time to time, we may solicit your participation in user surveys. In
        order to better understand and serve our user base, we may ask you for
        demographic information, including information related to your gender,
        ethnicity, race, age, sexual orientation, earnings and accessibility,
        which you may choose to share or not. We collect such information to
        ensure we provide fair and equal access to our services and to showcase
        Creators to Members for greater discoverability. We will store your
        survey responses as and when you participate in the surveys.
      </p>
      <p>
        <b>Event Information</b>
        <br />
        We may request information from you at in-person and online events. This
        is done to better tailor the event experience to those attending. This
        information may include your name, email address, payment details,
        mobile phone number and QR code, demographic information, social media
        and other online accounts you maintain, details around the types of
        works you create, and survey or feedback responses.
      </p>
      <p>
        <b>Blogs</b>
        <br />
        We may collect and store your email address, your comments and your
        profile information from our blog sites to better understand your
        interests and improve our services to you.
      </p>
      <p>
        <b>Information Collected by Third Parties</b>
        <br />
        <b>Cookie Policy</b>
        <br />
        We use Cookies on our website that collect information to allow us and
        third parties to understand your use of Gorudo. We may use cookies to
        distinguish you from other users of our websites. This may help us to
        provide you with a good experience when you browse our website and may
        also allow us to improve our website. By continuing to browse the
        website, you are agreeing to our use of cookies as well as the terms of
        this policy (the “Cookie Policy”). A cookie is a small file of letters
        and numbers that we may store on your browser or the hard drive of your
        computer if you agree. Cookies contain information that is transferred
        to your computer's hard drive. We may use the following cookies:
        <ul>
          <li>
            <b>Strictly necessary cookies.</b> These are cookies that are
            required for the operation of our websites. They include, for
            example, cookies that enable you to log into secure areas of our
            websites, use a shopping cart or make use of e-commerce payment
            processing services.
          </li>
          <li>
            <b>Analytical/performance cookies.</b> They allow us to recognise
            and count the number of visitors and to see how visitors move around
            our websites when they are using it. This may help us to improve the
            way our websites work, for example, by ensuring that users are
            finding what they are looking for easily.
          </li>
          <li>
            <b>Functionality cookies.</b> These cookies are used to recognise
            you when you return to our websites. They may enable us to
            personalise our content for you, greet you by name, or remember your
            preferences (for example, your choice of language or region).
          </li>
          <li>
            <b>Targeting cookies.</b> These cookies record your visit to our
            websites, the pages you have visited and the links you have
            followed.
          </li>
        </ul>
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies), you may not be able to access all or parts of our sites.
      </p>
      <p>
        <b>Social Media Features and Widgets</b>
        <br />
        We use social media features, including the Facebook Like button and
        widgets, such as the Share button or similar interactive mini-programs
        that run on Gorudo. If you provide your social media information to us,
        we may use it to interact with you on these social networks. These
        features may collect your IP address and other personal data, including
        which page you are visiting on our site, and may set a cookie to enable
        the feature to function properly. Social media features and widgets are
        either hosted by a third party or directly on Gorudo. Your interactions
        with these Features are governed by the privacy policy of the company
        providing it. For more information on the technologies used by these
        social networks, please refer to their specific privacy notices.
      </p>
      <p>
        <b>How We Use Your Information</b>
        <br />
        We process your information to: <br />
        <ul>
          <li>verify your identity to comply with international laws</li>
          <li>allow you to sign in to your account</li>
          <li>allow you to support a Creator’s membership tiers on Gorudo</li>
          <li>process membership payments and payouts</li>
          <li>send merchandise to your shipping address</li>
          <li>
            send you emails relevant to your usage, as controlled by your email
            preferences
          </li>
          <li>reply to your questions</li>
          <li>
            provide to you existing and enhanced services related to your Gorudo
            account
          </li>
          <li>promote your Gorudo account for greater discoverability</li>
          <li>spotlight and celebrate Creators</li>
          <li>
            market Gorudo products and services to you or to audiences similar
            to you based on your networks and common factors that others have
            with you, unless you opt out, as controlled by your email
            preferences
          </li>
          <li>
            understand how you use the service and create better tools for
            Creators to serve Members
          </li>
          <li>
            conduct research and development to improve Gorudo and develop
            future products
          </li>
          <li>prevent fraud and abuse on Gorudo</li>
          <li>
            provide you with reasonable accommodation, if you notify us of a
            disability
          </li>
        </ul>
      </p>
      <p>
        <b>Information We Share with Creators</b>
        <br />
        By becoming a Member of a Creator, you agree to have the following
        information shared with that Creator:
        <ul>
          <li>
            your name and email address, and other profile information you’ve
            provided
          </li>
          <li>any messages you send Creators through Gorudo</li>
          <li>your physical address, city, state, and country</li>
          <li>
            your phone number, when you have signed up to support a Creator who
            engages with you via text messages, or other communication apps
            (i.e. Whatsapp, Telegram, Discord etc.).{' '}
          </li>
          <li>
            all information about your pledge, including amount and start date
            but not your full payment card information
          </li>
          <li>
            some aggregated and anonymised data about how you use Gorudo that
            cannot be linked back to you or to any individual user
          </li>
        </ul>
        Creators agree to the terms of our Data Processing Agreement (DPA) when
        they start their membership programme. This DPA is called the Creator
        Privacy Promise. The Creator Privacy Promise is a Creator’s promise to
        protect the privacy of their Members during and beyond their Gorudo
        relationship. You can view a copy of the Creator Privacy Promise under
        the Terms of Use.
      </p>
      <p>
        <b>Information We Share with Third Parties</b>
        <br />
        We never sell your information to third parties. We will only share data
        with third parties, other than with Creators, under the following
        circumstances:
        <ul>
          <li>
            with your consent, to Creators’ service providers, to deliver
            benefits. You should read these service providers’ privacy policies
            to see how they use and share your data.
          </li>
          <li>
            with our service providers, who are companies that are contractually
            engaged with us to provide us with services, such as order
            fulfilment, email management, analysing data trends, credit card
            processing, multi-currency settlement solutions, increasing our
            brand awareness and user engagement with marketing initiatives, and
            fraud detection and prevention. These companies may have access to
            your data to perform their services, and are obligated by contract
            to safeguard any of your data they receive from us to the same
            extent that Gorudo protects it.
          </li>
          <li>
            to protect the security or integrity of Gorudo as well as to protect
            the rights, property and safety of Gorudo, its employees, users or
            others if we believe that disclosure is reasonably necessary to
            comply with a law, regulation or other valid legal process (e.g.
            subpoenas or warrants served on Gorudo). If we are going to release
            your data, we will do our best to promptly notify you by email
            unless we are prohibited by law from doing so.
          </li>
          <li>
            in connection with the sale, merger, bankruptcy, sale of assets or
            reorganisation of our company. We will notify you if a different
            company receives your data. The promises in this privacy policy
            apply to any data transferred to a new entity.
          </li>
          <li>
            with third-party apps used by Creators to help run their membership
            programmes.
          </li>
          <li>
            with partners who may have access to your data to perform their
            services.
          </li>
        </ul>
      </p>
      <p>
        <b>Information Gorudo Collects from Third Party Apps</b>
        <br />
        When you create a Gorudo account, you can elect to connect your social
        account(s) (e.g. Twitter, Facebook, Google) with Gorudo and we will
        collect and store some social information from those platforms, such as:
        <ul>
          <li>follower or subscriber counts</li>
          <li>post or upload counts</li>
          <li>view, like, and comment counts</li>
        </ul>
        This social information allows us to provide you with a better Gorudo
        experience and also helps guide future development of Gorudo. We use
        this data to:
        <ul>
          <li>help Creators and Members find each other on Gorudo</li>
          <li>assess how to make Creators more successful on Gorudo</li>
          <li>analyse and describe our business</li>
        </ul>
      </p>
      <p>
        <b>Information We Share with the Public</b>
        <br />
        The following information is publicly accessible:
        <ul>
          <li>
            your profile and information under about yourself section if you add
            that information
          </li>
          <li>
            your aggregated or anonymised usage data in blog posts, press
            releases or in other ways to share information about Gorudo’s usage.
            Aggregated or anonymised data cannot be linked back to any
            individual Gorudo user
          </li>
          <li>Newsletters that Creators choose to make public</li>
          <li>number of Members, broadcast and trade diary posts </li>
        </ul>
      </p>
      <p>
        <b>Marketing Activities</b>
        <br />
        By agreeing to our terms of use, you expressly agree that Gorudo may:
        <ul>
          <li>
            provide you with information about your service, service
            enhancements or new Gorudo products while you are on our website or
            using our apps.
          </li>
          <li>
            send you messages regarding your existing service or enhancements
            related to your existing service when you are off our platform via
            email or via text if you have signed up for a service or event
            notification that uses text messages.
          </li>
          <li>
            send you messages related to services we provide which are separate
            from your existing service via email, which you may opt out of.
          </li>
          <li>
            market Gorudo to you and audiences similar to you based on your
            networks and common factors that others have with you unless you opt
            out.
          </li>
          <li>
            send you marketing emails or texts if you don’t have a Gorudo
            account but have consented to receiving such messages, which you may
            opt out of.
          </li>
          <li>
            ask for demographic information to spotlight and celebrate Creators.
          </li>
          <li>promote your account for greater discoverability.</li>
          <li>
            ship physical items to you via mail as part of a promotional
            activity.
          </li>
        </ul>
      </p>
      <p>
        <b>Opting out of Marketing</b>
        <br />
        You may object to the use of your data for marketing purposes at any
        time, free of charge, when we collect your personal information and in
        every marketing communication. You may also object to the use of your
        data for marketing upon creation of your account or anytime thereafter
        by opting out by sending your request through email{' '}
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>. <br />
        Opting out will stop marketing emails. Please allow up to 30 days for
        your opt-out request to be processed. If you have an account with
        Gorudo, you will continue to receive service-related emails and texts if
        you have opted into receiving texts. You will also continue to receive
        service-related shipments of benefits to the designated delivery
        address. <br />
        We will never sell your information to third parties for marketing
        purposes without your prior consent.
      </p>
      <p>
        <b>Accessing, Updating the Information in Your Account</b>
        <br />
        You can access and update certain information associated with your
        account on the settings pages.
      </p>
      <p>
        <b>Deleting Your Account</b>
        <br />
        After you have deleted your account, we will only keep your information
        for as long as reasonably necessary to fulfill the purposes for which
        your personal information was originally collected. We will delete your
        information after a reasonable time.
      </p>
      <p>
        <b>Turning off Email / Web-portal Notifications</b>
        <br />
        You can change your email / web-portal notifications in your setting
        page. While this allows you to stop many notification emails, we will
        still send some critical service emails.
      </p>
      <p>
        <b>Turning off Mobile Notifications</b>
        <br />
        If you download the Gorudo App you may also receive notifications on
        your mobile device. These can be disabled in the App settings.
      </p>
      <p>
        <b>Verification of Requests</b>
        <br />
        Users maintain password-protected accounts with Gorudo. If you submit a
        request to exercise your privacy rights, you will be asked to verify the
        request by logging in to your Gorudo account. <br />
        If we are unable to verify your request then, for your protection, we
        reserve the right to deny the request.
      </p>
      <p>
        <b>International Transfer</b>
        <br />
        Please be aware that your personal data will be transferred to,
        processed, and stored in the Hong Kong. Data protection laws in from
        those in your country of residence may be different. You consent to the
        transfer of your information, including personal information, may be
        transferred to and stored in the Hong Kong and handled as described in
        this Policy <br />
        You agree and acknowledge that your personal information may be stored
        at or transferred within Hong Kong or a destination outside of Hong
        Kong. This will allow staff operating outside of Hong Kong (whether
        employed by us or by a third party engaged by us or otherwise authorized
        under this Privacy Policy to access your personal information) to
        process your information for the purposes of providing you with our
        services.
      </p>
      <p>
        <b>Compliance with EU Privacy Laws and Privacy Shield</b>
        <br />
        Gorudo is an international company that complies with the General Data
        Protection Regulation (GDPR). We act as Data Controllers, as defined
        under the GDPR, and we process personal data based on our legitimate
        interests and for contractual necessity in providing you the Service, as
        described in "How We Use Your Information". We also process and share
        personal information based on notification and users’ consent, which our
        users may revoke at any time. <br />
        Gorudo relies on various legal bases to lawfully execute international
        transfers of personal information, including standard contract clauses
        approved by the European Commission. <br />
        Gorudo complies with the EU-US Privacy Shield Framework and Swiss-US
        Privacy Shield Framework as set forth by the US Department of Commerce
        regarding the collection, use and retention of personal information
        transferred from the European Union and Switzerland to the United
        States. Under limited circumstances you may invoke binding arbitration
        before the Privacy Shield Panel. More Information about this arbitration
        process can be found at{' '}
        <a href="https://www.privacyshield.gov/article?id=G-Arbitration-Procedures">
          https://www.privacyshield.gov/article?id=G-Arbitration-Procedures
        </a>
        . <br />
        Gorudo is responsible for the processing of personal information it
        receives or subsequently transfers to a third party acting as an agent
        on Gorudo’s behalf. Gorudo complies with the Privacy Shield Principles
        for all onward transfers of personal information, including the onward
        transfer liability provisions.
      </p>
      <p>
        <b>Compliance with California Privacy Laws</b>
        <br />
        Effective 1 January 2020, the California Consumer Privacy Act (CCPA)
        allows California residents to request access to the specific pieces and
        categories of personal information that the business has collected about
        the consumer, the categories of sources for that information, the
        business or commercial purposes for collecting the information, and the
        categories of third parties with which the information was shared.{' '}
        <br />
        All users also have the right to submit a request for deletion of
        information by going sending an email request to{' '}
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>.
      </p>
      <p>
        <b>Exercising Your Data Rights</b>
        <br />
        Users in certain locations may have certain rights under the General
        Data Protection Regulation (GDPR) or under the California Consumer
        Privacy Act (CCPA) regarding data that Gorudo controls as a Data
        Controller as defined under the GDPR, including:
        <ul>
          <li>the right of access to their personal data,</li>
          <li>the right to correct or rectify any inaccurate personal data,</li>
          <li>
            the right to restrict or oppose the processing of personal data,
          </li>
          <li>the right to erase or delete their personal data,</li>
          <li>the right to personal data portability, and</li>
          <li>the right to opt-out of the sale of personal information.</li>
        </ul>
      </p>
      <p>
        <b>Prohibition against Discrimination</b>
        <br />
        We do not discriminate against users who exercise their privacy rights.
      </p>
      <p>
        <b>Our Data Retention Period</b>
        <br />
        We retain your account information for ten years after your account is
        last active unless you delete, or you request that we delete, your
        account. We may continue to retain some information even after you
        delete your account if we are required to do so in order to comply with
        various laws.
      </p>
      <p>
        <b>Children</b>
        <br />
        Gorudo is not intended for children under the age of 18 and children may
        not create an account or otherwise use Gorudo.
      </p>
      <p>
        <b>Changes</b>
        <br />
        We may sometimes make changes to this policy. If we make material
        changes that adversely affect your rights under this policy, we will let
        you know by posting an announcement on the site or sending you an email
        in advance of the changes coming into effect. Continuing to use Gorudo
        after a change to this policy means you accept the new policy. If you
        have any questions, please email{' '}
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>.
      </p>
    </div>
  );
}
