// boardcast tag string to array
export const tagStringToArray = (data) => {
  let newArray = [];
  data.map((item) => {
    var tagTagArray = item.tag ? item.tag.split(", ") : [];
    var filesArray = item.files ? item.files.split(", ") : [];
    delete item.tag;
    delete item.files;
    newArray.push({ ...item, tag: tagTagArray, files: filesArray });
  });

  return newArray;
};
