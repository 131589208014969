import { useTranslation } from "react-i18next";
export default function EmptyData({ isEmpty, message }) {
  const { t } = useTranslation();
  return (
    <div className="empty_data">
      <img src="/images/available.svg" alt="SVG" />
      <p>{message ?? t("no_data_text")}</p>
    </div>
  );
}
