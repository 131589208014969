import axios from "axios";
import {
  creatorPublicDetailsUrl,
  creatorPublicNewsletterUrl,
  creatorPublicTierUrl,
  newsLetterTierUrl,
} from "../../const";

// get creator public details
export const getCreatorPublicDetails = (creator_id) => {
  return axios.get(creatorPublicDetailsUrl, { params: { creator_id } });
};

// get public creator tier
export const getPublicCreatorTier = (creator_id) => {
  return axios.get(creatorPublicTierUrl, { params: { creator_id } });
};

// get creator newsletter
export const creatorPublicViewNewsletter = (creator_id) => {
  return axios.get(creatorPublicNewsletterUrl, {
    params: {
      creator_id,
      page: 1,
      limit: 10,
      search: "",
      tag: "",
      pin: "",
      is_schedule: "",
      post_id: "",
    },
  });
};

// get newsletter tier
export const newsletterTier = (post_id) => {
  return axios.get(newsLetterTierUrl, { params: { post_id } });
};
