import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Spacing from "../Spacing";
import parse from "html-react-parser";
import { submitContactForm } from "../../api/services/contact";
import { Icon } from "@iconify/react";

export default function Contact() {
  const { t } = useTranslation();
  const resetForm = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await submitContactForm(contactForm);
    if (res.data.success == 1) {
      resetForm.current.click();
      setSuccessMessage("Contact email sent successfully");
      setErrorMessage("");
    } else {
      setErrorMessage("Failed to send contact email");
    }
    setIsLoading(false);
  };
  return (
    <section style={{ backgroundColor: "#EEF5F9" }} id="contact">
      <Spacing lg="60" md="60" />
      <div className="cs-container">
        <form onSubmit={handleContactSubmit} className="cs-row cs-align_center">
          <div className="cs-col_6">
            <div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title">
                {t("contact_title")}
                <img src="/images/shapes/shape_1.svg" alt="Shape" />
              </h2>
              <div className="cs-section_subtitle">
                {parse(t("contact_subtitle"))} <br />
                support@gorudo.io
              </div>
              <Spacing lg="20" md="20" />
              <Link to="/" className="cs-btn cs-style1">
                {t("contact_btn_text")}
              </Link>
              <Spacing lg="0" md="50" />
            </div>
          </div>
          <div className="cs-col_6">
            {successMessage && <p className="cs-success">{successMessage}</p>}
            {errorMessage && <p className="cs-danger">{errorMessage}</p>}
            <label>{t("input_label_1")}</label>
            <input
              type="text"
              className="cs-form_field"
              placeholder={t("input_placeholder_1")}
              onChange={(e) =>
                setContactForm({ ...contactForm, name: e.target.value })
              }
              required
            />
            <Spacing lg="30" md="30" />
            <label>{t("input_label_2")}</label>
            <input
              type="email"
              className="cs-form_field"
              placeholder={t("input_placeholder_2")}
              onChange={(e) =>
                setContactForm({ ...contactForm, email: e.target.value })
              }
              required
            />
            <Spacing lg="30" md="30" />
            <label>{t("input_label_3")}</label>
            <textarea
              cols="30"
              rows="5"
              className="cs-form_field"
              onChange={(e) =>
                setContactForm({ ...contactForm, message: e.target.value })
              }
              required
              placeholder={t("input_placeholder_3")}
            ></textarea>
            <Spacing lg="30" md="30" />
            <button
              className={`cs-btn cs-style1 ${
                isLoading ? "cs-loading_wrap" : ""
              }`}
              style={{ maxWidth: "205px", width: "100%" }}
              disabled={isLoading}
            >
              {isLoading && (
                <span className="cs-btn_loading">
                  <Icon icon="la:spinner" />
                  {t("loading_text")}
                </span>
              )}
              {t("submit_btn")}
            </button>
            <button
              type="reset"
              style={{ display: "none" }}
              ref={resetForm}
            ></button>
          </div>
        </form>
      </div>
      <Spacing lg="60" md="60" />
    </section>
  );
}
