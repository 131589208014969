const baseURL = "https://api.gorudo.io/api/v1/";

export const creatorPublicDetailsUrl =
  baseURL + "member/creator/creator_public_detail";

export const creatorPublicTierUrl =
  baseURL + "member/creator/tiers_public_detail";
export const creatorPublicNewsletterUrl =
  baseURL + "member/newsletter/newsletter_public";
export const newsLetterTierUrl = baseURL + "settings/newsletter_tier_public";

export const submitContactUrl = baseURL + "user/contact_api";
