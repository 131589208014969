import { Route, Routes } from 'react-router-dom';
import CreatorPublicView from './components/CreatorPublicView';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import Login from './pages/Login';
import Faqs from './pages/Faqs';
import Privacy from './pages/Privacy';
import Register from './pages/Register';
import TermsOfUse from './pages/TermsOfUse';
import Disclaimer from './pages/Disclaimer';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="creator-public-view/:username"
          element={<CreatorPublicView />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
