import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import DropDown from './DropDown';
import './header.scss';
import LangauageDropdown from './LangauageDropdown';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t } = useTranslation();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [languageToggle, setLanguageToggle] = useState(false);

  const handelLanguageToggle = () => {
    setLanguageToggle(!languageToggle);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);
  return (
    <>
      <header
        className={`cs-site_header cs-style1 cs-primary_font ${
          isSticky ? 'cs-sticky_header_active' : ''
        }`}
      >
        <Section className="cs-main_header">
          <Section className="container">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.svg" alt="Logo" />
                </Link>
                <Section className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li className="menu-item-has-children">
                      <a onClick={() => setMobileToggle(false)}>
                        {t('nav_item_1')}
                      </a>
                      <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="/terms-of-use"
                              onClick={() => setMobileToggle(false)}
                            >
                              {t('nav_item_2')}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/privacy"
                              onClick={() => setMobileToggle(false)}
                            >
                              {t('nav_item_3')}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/disclaimer"
                              onClick={() => setMobileToggle(false)}
                            >
                              {t('disclaimer')}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/faqs"
                              onClick={() => setMobileToggle(false)}
                            >
                              Faqs
                            </Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        onClick={() => setMobileToggle(false)}
                      >
                        {t('nav_item_5')}
                      </Link>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Section>
              </Section>
              <Section className="cs-main_header_right">
                <div className="cs-toolbox">
                  <Link to="/login" className="cs-header_btn">
                    {t('nav_item_6')}
                  </Link>
                  <Link to="/register" className="cs-header_btn cs-color1">
                    {t('nav_item_7')}
                  </Link>
                  <LangauageDropdown
                    handelLanguageToggle={handelLanguageToggle}
                    languageToggle={languageToggle}
                    close={setLanguageToggle}
                  />
                </div>
              </Section>
            </Section>
          </Section>
        </Section>
      </header>
    </>
  );
}
