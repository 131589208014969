import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

export default function LoadingSpinner() {
  const { t } = useTranslation();
  return (
    <span className="cs-section_loading">
      <Icon icon="la:spinner" />
      Loading
    </span>
  );
}
