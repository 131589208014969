import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function AccordionMember() {
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  return (
    <div className="cs-accordians cs-style1">
      <div className={`cs-accordian ${selected === 0 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(0)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q1'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A1'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 1 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(1)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q2'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A2'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 2 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(2)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q3'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A3'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 3 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(3)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q4'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A4'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 4 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(4)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q5'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A5'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 5 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(5)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q6'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A6'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 6 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(6)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q7'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A7'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 7 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(7)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q8'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A8'))}</p>
          </div>
        </div>
      </div>
      <div className={`cs-accordian ${selected === 8 ? 'active' : ''}`}>
        <div className="cs-accordian_head" onClick={() => handelToggle(8)}>
          <h2 className="cs-accordian_title">
            {parse(t('member_accordion_Q9'))}
          </h2>
          <span className="cs-accordian_toggle">
            <svg
              width={28}
              height={14}
              viewBox="0 0 28 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.177 1L14.7162 12.5L27.1271 1" stroke="#535353" />
            </svg>
          </span>
        </div>
        <div className="cs-accordian_body">
          <div className="cs-accordian_body_in">
            <p>{parse(t('member_accordion_A9'))}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
