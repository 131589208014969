import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../../components/Spacing';
import { pageTitle } from '../../helper';
import { useTranslation } from 'react-i18next';
import TermsOfUseEN from './TermsOfUseEN';
import TermsOfUseTC from './TermsOfUseTC';

export default function TermsOfUse() {
  pageTitle('Terms Of Use');
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="75" md="75" />
      <div
        className="cs-page_head cs-bg"
        style={{ backgroundImage: 'url(/images/terms_bg.jpg)' }}
      >
        {/* <div className="cs-container">
          <div className="cs-page_head_in">
            <h1 className="cs-page_title">{t('terms_title')}</h1>
            <div className="cs-page_subtitle">{t('terms_subtitle')}</div>
          </div>
        </div> */}
      </div>
      <div className="cs-container">
        <Spacing lg="30" md="30" />
        <div className="cs-section_heading cs-style3">
          <div className="cs-text_center">
            <Link to="/">
              <img src="/images/icons/icon4.svg" alt="Icon" />
            </Link>
          </div>
          <Spacing lg="15" md="15" />
          <h2 className="cs-section_title">{t('terms_text_1')}</h2>
          {t('language') === 'en' && <TermsOfUseEN />}
          {t('language') === 'tc' && <TermsOfUseTC />}
        </div>
        <Spacing lg="60" md="40" />
      </div>
    </>
  );
}
