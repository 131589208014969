import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/Contact';
import Spacing from '../components/Spacing';

export default function ContactUs() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="75" md="75" />
      <div
        className="cs-page_head cs-bg"
        style={{ backgroundImage: 'url(/images/faq_bg.jpg)' }}
      >
        <div className="cs-container">
          <div className="cs-page_head_in">
            <h1 className="cs-page_title">{t('contact_title')}</h1>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
}
