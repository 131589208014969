import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="cs-footer">
      <div className="cs-container">
        <div className="cs-footer_in">
          <div className="cs-footer_left">
            <div className="cs-footer_logo">
              <img src="/images/logo_white.svg" alt="Logo" />
            </div>
          </div>
          <div className="cs-footer_right">
            <div className="cs-footer_col"></div>
            <div className="cs-footer_col">
              <ul className="cs-footer_menu">
                <li>
                  <Link to="/terms-of-use">{t("footer_nav_1")}</Link>
                </li>
                <li>
                  <Link to="/privacy">{t("footer_nav_2")}</Link>
                </li>
                <li>
                  <Link to="/disclaimer">{t("disclaimer")}</Link>
                </li>
                <li>
                  <Link to="/faqs">{t("footer_nav_3")}</Link>
                </li>
              </ul>
            </div>
            <div className="cs-footer_col">
              <div className="cs-footer_title">{t("footer_social_title")}</div>
              <Link to="/" className="cs-footer_btn_1">
                <Icon icon="fa6-brands:square-facebook" />
              </Link>
              <br />
              <Link to="/" className="cs-footer_btn_2">
                <>
                  <Icon icon="ph:envelope-simple" />
                  admin@gorudo.io
                </>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
