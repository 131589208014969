import React from 'react';

export default function PrivacyTC() {
  return (
    <div className="cs-general_content">
      <b>隱私政策</b> <br />
      <b>最近更新：2023年6月</b> <br />
      <p>
        此隱私政策適用於我們平台的會員，創作者和所有使用者，並且是我們使用條款的一部分。{' '}
        <br />
        Gorudo是一家全球性公司。通過使用我們的平台，您同意您直接向我們提供的個人信息，或者通過您使用平台收集的信息，可能會轉移到香港並按照本政策所描述的方式進行處理和存儲。
      </p>
      <p>
        <b>通過您的帳戶提供的信息</b>
        <br />
        這是您通過文本字段向我們提供的信息，例如您的姓名，付款信息和福利。我們收集的信息取決於您是創建帳戶，成為會員還是成為創作者的不同。
        <ul>
          <li>姓和名</li>
          <li>電子郵件地址</li>
          <li>顯示名稱</li>
          <li>密碼</li>
          <li>居住國家</li>
        </ul>
        您還可以使用 Facebook 或 Google 帳戶註冊。我們將請求許可權從您的
        Facebook 或 Google
        帳戶中訪問基本信息，例如您的姓名，電子郵件和個人資料照片。您可以隨時選擇停止與我們共享該信息，方法是前往
        Facebook 或 Google，刪除 Gorudo 對該帳戶的訪問權限和您的登錄權限。{' '}
        <br />
        您還可以選擇向您的公共檔案添加更多信息，例如描述部分，社交媒體鏈接和通訊工具鏈接，我們將與您在
        Gorudo 上提交的任何評論或消息一起存儲。
      </p>
      <p>
        <b>會員</b> <br />
        會員是指加入 Gorudo
        的會員平台支持創作者會員內容的人。作為會員，您必須向我們的付款處理器提供付款信息。您可以在
        Stripe 網站上查看這些付款處理器的隱私政策。此時 Gorudo
        不會收到您的完整卡號。付款處理器向我們提供代表您的帳戶，您的卡到期日期，卡類型和卡號的最後四位數的令牌。如果您需要向付款處理器提供您的姓名和電子郵件地址，那麼他們還會向我們提供該信息。
        <br />
        我們收集和處理有關您支持的創作者，您支持他們的級別，您收到的福利以及您支持他們的頻率的信息。
      </p>
      <p>
        <b>創作者</b> <br />
        創作者是指通過 Gorudo
        平台創建和提供內容給其會員的人。要成為創作者，您必須創建一個描述自己和任何提供的福利的頁面。要收到付款，您必須在我們的付款合作夥伴
        Stripe
        上創建帳戶。我們還可能需要您的銀行帳戶信息以進行付款。為了納稅目的，您還必須向我們提供其他信息。根據您的所在地，您可能必須填寫某些組合的表格，包括：
        <ul>
          <li>姓和名</li>
          <li>地址</li>
          <li>社會安全號碼或雇主身份證號碼</li>
          <li>公民身份國家</li>
          <li>外國稅務識別號碼</li>
          <li>出生日期</li>
          <li>用於付款的銀行帳戶信息</li>
          <li>電話號碼</li>
        </ul>
      </p>
      <p>
        <b>我們收集的其他信息</b>
        <br />
        <b>自動收集的信息</b> <br />
        當您使用我們的平台時，我們會自動收集信息，或者透過第三方分析提供者收集信息。我們可能會存儲使用信息，例如您用來訪問
        Gorudo 的設備類型，操作系統，瀏覽器類型，IP 地址，設備
        ID，您訪問或請求的頁面，點擊的鏈接，引用站點，用戶互動以及您的搜索詞。我們還從您自我披露的國家，您的
        IP 地址和您的付款卡中推斷出您的位置。
      </p>
      <p>
        <b>用戶調查</b>
        <br />
        我們可能會不時徵求您參加用戶調查。為了更好地了解和服務我們的用戶群，我們可能會要求您提供人口統計信息，包括與您的性別，種族，年齡，性取向，收入和可訪問性有關的信息，您可以選擇分享或不分享。我們收集此類信息是為了確保我們公平且平等地提供服務，並向會員展示創作者以增加可發現性。當您參加調查時，我們將存儲您的調查回覆。
      </p>
      <p>
        <b>活動信息</b>
        <br />
        我們可能會在現場和在線活動中向您請求信息。這是為了更好地將活動體驗定制給參加者。此信息可能包括您的姓名，電子郵件地址，付款詳細信息，移動電話號碼和
        QR
        碼，人口統計信息，您維護的社交媒體和其他在線帳戶，有關您創建的工作類型的詳細信息以及調查或反饋回覆。
      </p>
      <p>
        <b>博客</b>
        <br />
        我們可能會從我們的博客網站收集和存儲您的電子郵件地址、評論和個人資料，以更好地了解您的興趣並改善對您的服務。
      </p>
      <p>
        <b>第三方收集的資訊</b>
        <br />
        <b>Cookie 政策</b>
        <br />
        我們在我們的網站上使用 Cookies，以收集資訊，使我們和第三方了解您使用
        Gorudo 的方式。我們可能使用 Cookies
        來區分您和其他使用者。這可以幫助我們在您瀏覽我們的網站時提供良好的體驗，並且也可以讓我們改善我們的網站。繼續瀏覽網站表示您同意我們使用
        Cookies，以及本政策（“Cookie 政策”）的條款。Cookie
        是一個小文件，包含字母和數字，如果您同意，我們可以將其存儲在您的瀏覽器或電腦硬盤上。Cookie
        包含轉移至您電腦硬盤的資訊。我們可能使用以下 Cookies：
        <ul>
          <li>
            嚴格必要的 Cookies。這些 Cookies
            是我們網站運作所必需的。例如，使您能夠登錄我們網站的安全區域，使用購物車或使用電子商務支付處理服務等
            Cookies。
          </li>
          <li>
            分析/效能 Cookies。當使用者使用我們的網站時，這些 Cookies
            允許我們識別和計數訪問者的數量，以及訪問者在使用時如何移動。這可能有助於我們改進我們的網站運作方式，例如，確保使用者輕鬆找到他們正在尋找的內容。
          </li>
          <li>
            功能性 Cookies。這些 Cookies
            用於識別您返回我們的網站時的身份。它們可能使我們能夠為您個性化我們的內容，通過姓名向您問候，或記住您的偏好（例如，您的語言或地區的選擇）。{' '}
          </li>
          <li>
            定向 Cookies。這些 Cookies
            記錄您訪問我們網站的情況，您訪問的頁面以及您所跟隨的鏈接。
          </li>
        </ul>
        您可以啟用您的瀏覽器設置，以拒絕所有或某些 Cookies 的設置，從而阻止
        Cookies。但是，如果您使用您的瀏覽器設置來阻止所有 Cookies（包括必要的
        Cookies），您可能無法訪問我們網站的所有或部分功能。
      </p>
      <p>
        <b>社交媒體功能和小工具</b>
        <br />
        我們使用社交媒體功能，包括 Facebook
        的“讚”按鈕和小工具，例如“分享”按鈕或類似的交互式小程序，在 Gorudo
        上運行。如果您向我們提供您的社交媒體資訊，我們可能會使用它在這些社交網絡上與您互動。這些功能可能會收集您的
        IP 地址和其他個人資料，包括您正在訪問的頁面，並可能設置 Cookie
        以使功能正常運作。社交媒體功能和小工具可以由第三方或直接在 Gorudo
        上託管。您與這些功能的互動受其提供者的隱私政策規管。有關這些社交網絡使用的技術的更多資訊，請參閱其具體的隱私通知。
      </p>
      <p>
        <b>我們如何使用您的資訊</b>
        <br />
        我們處理您的資訊以：
        <br />
        <ul>
          <li> 驗證您的身份以遵守國際法律</li>
          <li>允許您登錄您的帳戶</li>
          <li>允許您支持 Gorudo 上 Creator 的會員級別</li>
          <li>處理會員付款和支付</li>
          <li>向您的送貨地址發送商品</li>
          <li>
            發送與您的使用相關的電子郵件，根據您的電子郵件偏好設置進行控制
          </li>
          <li>回答您的問題</li>
          <li>為您的 Gorudo 帳戶提供現有和增強的服務</li>
          <li>促進您的 Gorudo 帳戶以提高可發現性</li>
          <li>突出和慶祝創作者</li>
          <li>
            根據您的網絡和與您相同的共同因素向您或類似您的觀眾推廣 Gorudo
            產品和服務，除非您選擇退出，根據您的電子郵件偏好進行控制
          </li>
          <li>了解您如何使用服務，並為創作者創建更好的工具來服務會員</li>
          <li>進行研究和開發，以改進 Gorudo 和開發未來產品</li>
          <li>在 Gorudo 上防止欺詐和濫用</li>
          <li>如果您通知我們有殘障，為您提供合理的住宿。</li>
        </ul>
      </p>
      <p>
        <b>與 Creator 分享的資訊</b>
        <br />
        成為 Creator 的會員時，您同意將以下資訊與該 Creator 分享：
        <ul>
          <li>您提供的姓名、電子郵件地址和其他個人資訊</li>
          <li>您通過 Gorudo 發送給 Creator 的任何信息</li>
          <li>您的物理地址、城市、州和國家</li>
          <li>
            當您已註冊支持與您通過短信、WhatsApp、Telegram、Discord
            等通訊應用程式互動的 Creator 時，您的電話號碼。
          </li>
          <li>
            關於您的承諾的所有資訊，包括金額和開始日期，但不包括您的完整付款卡資訊。
          </li>
          <li>
            一些關於您如何使用 Gorudo
            的匿名資料，這些資料無法與您或任何單個用戶關聯。
          </li>
        </ul>
        當創作者開始其會員計劃時，創作者同意遵守我們的數據處理協議
        (DPA)，該協議稱為 Creator Privacy Promise。 Creator Privacy Promise
        是創作者在 Gorudo
        關係期間及以後保護會員隱私的承諾。您可以在使用條款下查看 Creator Privacy
        Promise 的副本。
      </p>
      <p>
        <b>我們與第三方共享的信息</b>
        <br />
        我們從不將您的信息出售給第三方。除創作者之外，我們僅在以下情況下將數據與第三方共享：
        <ul>
          <li>
            在您的同意下，向創作者的服務提供商共享信息以提供福利。您應閱讀這些服務提供商的隱私政策，以查看他們如何使用和共享您的數據。{' '}
          </li>
          <li>
            與我們的服務提供商共享，這些公司與我們簽訂合同，為我們提供服務，例如訂單履行，電子郵件管理，分析數據趨勢，信用卡處理，多貨幣結算解決方案，通過市場推廣活動提高品牌知名度和用戶參與度，以及欺詐檢測和預防。這些公司可能會訪問您的數據以履行其服務，並受到合同的義務保護，以與Gorudo保護您的任何數據同等的程度保護其接收的數據。
          </li>
          <li>
            為保護Gorudo的安全或完整性以及保護Gorudo、其員工、用戶或其他人的權利、財產和安全，如果我們認為披露合理必要以遵守法律、法規或其他有效的法律程序（例如，傳票或法院令）
            ，則會公開披露您的數據。如果我們要公開披露您的數據，我們將盡力通過電子郵件及時通知您，除非法律禁止我們這樣做。
          </li>
          <li>
            在我們公司出售、合併、破產、出售資產或重組的情況下進行共享。如果不同的公司收到您的數據，我們將通知您。本隱私政策中的承諾適用於轉移給新實體的任何數據。
          </li>
          <li>與創作者使用的第三方應用程序共享，以幫助運行其會員計劃。</li>
          <li>與可能訪問您的數據以履行其服務的合作夥伴共享。</li>
        </ul>
      </p>
      <p>
        <b>Gorudo從第三方應用程序收集的信息</b>
        <br />
        當您創建Gorudo帳戶時，您可以選擇將您的社交帳戶（例如Twitter、Facebook、Google）與Gorudo連接，我們將從這些平台收集和存儲一些社交信息，例如：
        <ul>
          <li>粉絲或訂閱者數量</li>
          <li>發布或上傳數量</li>
          <li>查看、喜歡和評論數量</li>
        </ul>
        這些社交信息可以讓我們為您提供更好的Gorudo體驗，並有助於指導未來的Gorudo開發。我們使用此數據來：
        <ul>
          <li>幫助創作者和會員在Gorudo上找到彼此</li>
          <li>評估如何使創作者在Gorudo上更成功</li>
          <li>分析和描述我們的業務</li>
        </ul>
      </p>
      <p>
        <b>我們與公眾分享的信息</b>
        <br />
        以下信息可公開訪問：
        <ul>
          <li>如果您添加了該信息，則您的檔案和有關自己部分的信息</li>
          <li>
            在博客文章、新聞稿或其他方式中，以匿名或匯總的方式分享有關Gorudo使用情況的信息。匯總或匿名數據無法追溯到任何個人Gorudo用戶
          </li>
          <li> 創作者選擇公開的通訊</li>
          <li>會員人數、廣播和交易日記帖子 </li>
        </ul>
      </p>
      <p>
        <b>市場活動</b>
        <br />
        通過同意我們的使用條款，您明確同意Gorudo可以：
        <ul>
          <li>
            在您使用我們的網站或應用程序時，向您提供有關您的服務、服務增強或新的Gorudo產品的信息。
          </li>
          <li>
            在我們平台外時，通過電子郵件或通過使用短信通知服務或事件的簽名，向您發送有關您現有服務或與您現有服務相關的增強的消息。
          </li>
          <li>
            通過電子郵件向您發送與我們提供的與您現有服務無關的服務有關的消息，您可以選擇退出。
          </li>
          <li>
            基於您的網絡和其他人與您具有的共同因素，將Gorudo推銷給您和類似您的觀眾，除非您選擇退出。
          </li>
          <li>
            如果您沒有Gorudo帳戶但已同意接收此類消息，則向您發送營銷電子郵件或短信，您可以選擇退出。
          </li>
          <li>要求人口統計信息以突出和慶祝創作者。</li>
          <li>促進您的帳戶更容易被發現。</li>
          <li>通過郵寄向您發送實體物品作為促銷活動的一部分。</li>
        </ul>
      </p>
      <p>
        <b>退出市場營銷</b>
        <br />
        您可以在我們收集您的個人信息時以及在每次營銷通訊中隨時免費反對使用您的數據進行營銷。您也可以在創建帳戶時或之後的任何時間通過發送電子郵件
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>進行退出。{' '}
        <br />
        退出將停止營銷電子郵件。請允許最多30天處理您的退訂請求。如果您在Gorudo上有帳戶，則如果您已選擇接收短信，則您將繼續收到與服務相關的電子郵件和短信。如果您已經選擇接收短信，您還將繼續收到與服務相關的福利發貨到指定的交付地址。{' '}
        <br />
        未經您的事先同意，我們永遠不會將您的信息出售給第三方進行營銷。
      </p>
      <p>
        <b>訪問，更新您的帳戶中的信息</b>
        <br />
        您可以在設置頁面上訪問和更新與您的帳戶相關的某些信息。
      </p>
      <p>
        <b>刪除您的帳戶</b>
        <br />
        當您刪除帳戶後，我們只會保留您的信息，以便履行最初收集個人信息的目的所必需的合理時間。我們會在合理時間後刪除您的信息。
      </p>
      <p>
        <b>關閉電子郵件/網站通知</b>
        <br />
        您可以在設置頁面中更改您的電子郵件/網站通知。雖然這可以讓您停止接收許多通知電子郵件，但我們仍會發送一些關鍵的服務電子郵件。
      </p>
      <p>
        <b> 關閉手機通知</b>
        <br />
        如果您下載Gorudo
        App，您也可以在您的移動設備上接收通知。您可以在應用程式設置中禁用這些通知。
      </p>
      <p>
        <b>請求驗證</b>
        <br />
        用戶在Gorudo上維護受密碼保護的帳戶。如果您提交請求以行使您的隱私權利，我們將要求您通過登錄Gorudo帳戶進行驗證。{' '}
        <br />
        如果我們無法驗證您的請求，為了保護您，我們保留拒絕請求的權利。
      </p>
      <p>
        <b>國際轉移</b>
        <br />
        請注意，您的個人數據將被轉移到香港進行處理和存儲。您所在國家的數據保護法可能與香港不同。您同意轉移您的信息，包括個人信息，可能會被轉移到香港並按照本政策所描述的方式進行處理。您同意和確認，您的個人信息可能存儲在香港或香港以外的目的地。這將使在香港以外運營的工作人員（無論是我們僱用的還是由我們聘請的第三方或根據本隱私政策的授權以其他方式訪問您的個人信息的人員）能夠為提供服務而處理您的信息。
      </p>
      <p>
        <b>遵守歐盟隱私法</b>
        <br />
        Gorudo是一家國際公司，遵守《通用數據保護條例》（GDPR）。我們作為GDPR下定義的數據控制器，基於我們的合法利益和為提供服務所必需的合同必要性來處理個人數據，如“我們如何使用您的信息”中所述。我們還基於通知和用戶同意來處理和共享個人信息，我們的用戶可以隨時撤銷同意。{' '}
        <br />
        Gorudo依賴各種法律基礎合法地執行個人信息的國際轉移，包括歐洲委員會批准的標準合同條款。{' '}
        <br />
        Gorudo遵守歐盟隱私盾框架和瑞士 -
        美國隱私盾框架，該框架由美國商務部制定，關於從歐盟和瑞士轉移的個人信息的收集、使用和保留。在有限的情況下，您可以在隱私盾小組之前要求進行具有約束力的仲裁。有關此仲裁程序的更多信息可以在
        <a href="https://www.privacyshield.gov/article?id=G-Arbitration-Procedures">
          https://www.privacyshield.gov/article?id=G-Arbitration-Procedures
          中找到。
        </a>
        <br />
        Gorudo對其收到或隨後轉移給代表Gorudo行事的第三方的個人信息的處理負責。對於所有進一步轉移個人信息，包括轉移責任規定，Gorudo遵守隱私盾原則。
      </p>
      <p>
        <b>遵守加州隱私法</b>
        <br />
        自2020年1月1日起，加利福尼亞消費者隱私法（CCPA）允許加利福尼亞居民要求訪問商家收集有關消費者的具體個人信息和類別，該信息的來源類別，收集信息的商業目的以及信息共享的第三方類別。
        <br />
        所有用戶也有權通過向
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>
        發送電子郵件請求刪除信息。
      </p>
      <p>
        <b>行使您的數據權利</b>
        <br />
        在某些地區，用戶可能根據《通用數據保護條例》（GDPR）或《加利福尼亞消費者隱私法》（CCPA）對Gorudo作為GDPR下定義的數據控制器控制的數據享有某些權利，包括：
        <ul>
          <li>訪問其個人數據的權利，</li>
          <li>更正或糾正任何不准確的個人數據的權利，</li>
          <li>限制或反對處理個人數據的權利，</li>
          <li>刪除其個人數據的權利，</li>
          <li>個人數據可移植性的權利，以及</li>
          <li>選擇退出出售個人信息的權利。</li>
        </ul>
      </p>
      <p>
        <b>禁止歧視</b>
        <br />
        我們不歧視行使其隱私權利的用戶。
      </p>
      <p>
        <b>我們的數據保留期限</b>
        <br />
        除非您刪除帳戶或要求刪除帳戶，否則我們將保留您的帳戶信息十年。即使您刪除帳戶，我們可能仍會繼續保留某些信息，如果根據各種法律要求必須這樣做。
      </p>
      <p>
        <b>兒童</b>
        <br />
        Gorudo不適用於18歲以下的兒童，兒童不得創建帳戶或以其他方式使用Gorudo。
      </p>
      <p>
        <b>更改</b>
        <br />
        我們有時會對此政策進行更改。如果我們對此政策的更改會不利影響您在此政策下的權利，我們會提前通過在網站上張貼公告或發送電子郵件通知您。繼續使用Gorudo表示您接受新政策。如果您有任何問題，請發送電子郵件至
        <a href="mailto:support@gorudo.io">support@gorudo.io。</a>.
      </p>
    </div>
  );
}
