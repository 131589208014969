import React from "react";

export default function TermsOfUseEN() {
  return (
    <div className="cs-general_content">
      <b>Terms of Use</b> <br />
      <b>Last update: June 2023</b> <br />
      <b>Welcome to Gorudo!</b> <br />
      <p>
        Gorudo is a membership platform that enables Creators to be paid by
        their Members by sharing their financial knowledge and ideas. We hope to
        make it easier for Creators to share their personal knowledge and ideas
        instantly with their Members.{" "}
      </p>
      <p>
        These are Gorudo’s terms of use, and they apply to all users of the
        Gorudo platform. “We,” “our” or “us” refers to Gorudo Company Limited
        and our subsidiaries. “Gorudo” refers to this platform and the services
        offered by us. By using Gorudo you agree to these terms and to the other
        policies we post. Please read them carefully and let us know if you have
        any questions. For information about our data practices, please see
        our Privacy Policy, which includes our Cookie Policy. We can collect and
        use your information in accordance with those policies.
      </p>
      <p>
        <b>Your account</b>
        <br />
        When you create an account you must provide us with accurate
        information, in good faith, and you agree to keep your information
        updated if it changes. To create an account you must be at least 13
        years old. To join a Creator’s membership as a Member, or provide a
        membership as a Creator, you must be at least 18 years old or have your
        parent’s permission. You are responsible for anything that occurs when
        anyone is signed in to your account, as well as the security of the
        account. Please contact us immediately if you believe your account is
        compromised.
      </p>
      <p>
        <b>Abusive conduct</b> <br />
        You are responsible for all activity on your account. If you violate our
        policies, we may terminate your account. Don’t do anything illegal,
        abusive towards others, or that abuses our site in a technical way. If
        you are a Creator raising funds on Gorudo, we may be held accountable
        for what you do with those funds. As a result, we also look at what you
        do with your membership off our platform.
      </p>
      <p>
        <b>Creators</b>
        <br />A Creator is someone who creates a creator account on Gorudo to
        engage with their Gorudo Members who have purchased memberships from
        Gorudo to support the Creator’s content. There are a lot of details
        below involving payments, fees, taxes and restrictions that you should
        read in full if you are a Creator.
      </p>
      <p>
        <b>Membership</b> <br />
        To become a Creator, simply create a creator account and create your
        membership. When you join Gorudo, you become part of the Gorudo
        community. You can use creator tools that Gorudo provides, share your
        personal knowledge and ideas, and receive recurring revenue from your
        page. On Gorudo, you can provide your Members with unique benefits they
        want, like additional access, exclusivity, and engaging experiences. In
        turn, you will receive loyal support from your Members, and recurring
        revenue from memberships that Members have purchased from Gorudo.
      </p>
      <p>
        <b>Payments</b>
        <br />
        As a Creator you make your membership available on Gorudo, and we
        provide membership to your Members on a subscription basis. We also
        handle payments issues such as fraud, chargebacks and resolution of
        payments disputes. We try to provide timely access to your funds, but
        you may occasionally experience delays in accessing your funds. We may
        also block or hold payments for violations of our policies or for
        compliance reasons, including collecting tax reporting information. When
        payments are delayed or blocked, we try to communicate the reason to you
        promptly. If you have questions about a payments block, please reach out
        to us. In order to protect Creators, we may block Member’s payments if
        we believe them to be fraudulent. Sometimes activities like refunds can
        put your account balance into the negative. If your balance becomes
        negative then we reserve the right to recover those funds from future
        payments.
      </p>
      <p>
        <b>Fees</b>
        <br />
        As a Creator there are two fees associated with your membership on
        Gorudo. The first is the platform fee, which varies depending on
        the level of service (Gorudo Plan) you select. The second is the payment
        processing fee, which is dependent on the currency selected by the
        Creator.
        <ul>
          <li>
            The settlement currency is in US Dollars and the payment processing
            fee is 4.4%+0.3 USD per successful transaction. The 2% currency rate
            will occur only when the members make payments in other currencies.
            For example, Euro will be converted into USD by mid market rate and
            there's a 2% conversion fee on the exchange rate (not on the
            payment). There may be additional charges by your bank / credit card
            provider outside of our control.
          </li>
        </ul>
        Depending on your Member’s locations, some banks may charge your Member
        a foreign transaction fee for their membership subscription. Gorudo does
        not control this charge, but it is typically around 3.0% .
      </p>
      <p>
        <b>Tax</b>
        <br />
        As a Creator, you are responsible to fulfil your own tax obligations and
        we encourage you to discuss with your own tax accountant / advisor if
        necessary to ensure proper filings are made to relevant tax authorities.
      </p>
      <p>
        <b>Financial advice</b>
        <br />
        As a Creator, you are responsible to ensure the content and creations
        does not constitute as an offer to sell, a solicitation of an offer to
        buy or an offer to purchase any securities, nor should it be deemed to
        be an offer, or a solicitation of an offer, to purchase or sell, unless
        you have the relevant financial license to conduct such activities.
      </p>
      <p>
        <b>Restrictions</b>
        <br />
        We don’t allow content and creations that violate our policies. A
        summary of those rules is that we don’t allow:
        <ul>
          <li>Illegal creations or benefits.</li>
          <li>Content or creations that are abusive towards other people.</li>
          <li>
            Content or creations that use others’ intellectual property, unless
            you have written permission to use it, or your use is protected by
            fair use.
          </li>
          <li>
            Content or creations with real people engaging in sexual acts.
          </li>
          <li>Creations that involve raffles or prizes based on chance.</li>
        </ul>
        If your fans include people under the age of 18, then please remind them
        that they need permission to join your membership, and those under the
        age of 13 cannot use Gorudo. We are not required to allow any particular
        person or group of persons to be your Member. <br />
        As a Creator, you are also responsible for keeping Members’ data safe.
        You can see what is required in our Data Processing Agreement. An
        account is tied to your content and cannot be sold or transferred for
        use by another Creator. <br />
        If a Creator violates the restrictions outlined in the Gorudo policies,
        Gorudo may take actions such as removing or limiting the Creator's
        access to the platform, suspending or terminating the Creator's account,
        or removing the Creator's content. Gorudo may also take legal action
        against the Creator if necessary. <br />
        Additionally, if a Creator violates the Data Processing Agreement or
        fails to keep Members' data safe, Gorudo may terminate the Creator's
        account and take legal action against them if necessary. <br />
        It is important for Creators to adhere to the restrictions and policies
        outlined by Gorudo to ensure a safe and positive environment for all
        Members.
      </p>
      <p>
        <b>Members</b>
        <br />A Member is someone who purchases memberships from Gorudo to
        support their favorite Creators’ content. Through a paid membership on
        Gorudo, Members directly receive content from Creators they support,
        using tools supplied by Gorudo, and through our platform have the
        opportunity to engage with a other Creators. <br />
        As a Member, you’re joining Gorudo to be part of an exciting movement to
        support a Creator’s membership while you get special benefits, which may
        include access to your Creator, exclusivity, and engaging experiences
        with the Creator. In exchange, you pay Gorudo for membership programs,
        on a subscription basis, to access and support Creators you like. <br />
        The timing and amount of each membership subscription depends on the
        type of membership agreement you enter into, and the Creator you
        support. You can see the details as you join, as well as in the receipt
        sent to you after each successful payment. You can view all your active
        membership subscriptions and billing history on your subscription page.{" "}
        <br />
        To become a Member, simply create a member account, add your preferred
        payment method, and join a membership program on Gorudo to start
        supporting your Creator. All membership programs are paid in a monthly
        subscription where you pay per month. <br />
        You may cancel your membership payments at any time. Terminating or
        lowering the tier support of membership will go into effect in the
        subsequent (not current) payment term. Increasing your payment to a
        higher tier level in an annual membership will go into effect in the
        current payment term. <br />
        These include when you cancel your membership subscription, your payment
        method fails, the Creator blocks you, or the Creator deletes their
        account. We are not required to allow you to be a Member of any
        particular Creator. <br />
        Creators’ memberships vary and we have limited control over the quality
        and specific offerings. We attempt to screen for fraudulent Creator
        pages, but cannot guarantee the identity of Creators or the validity of
        any claims they make. We appreciate your help reporting suspicious
        Creator pages so we can keep Gorudo safe.
        <br />
        Depending on your location, some banks may charge you a foreign
        transaction fee for your membership subscription. Gorudo does not
        control this charge, but it is typically around 3% . Please contact your
        bank for more information. <br />
        <b>Refunds.</b> Our policy is No Refunds, though we will allow for some
        exceptions where refunds are granted in our sole discretion. <br />
        <b>
          Important disclaimer regarding the information and content on Gorudo{" "}
        </b>{" "}
        <br />
        Do Not Make Any Investment Decision Based Upon Anything Found On This
        Site. <br />
        As a Member, you agree the views on this Site and Services are the
        Creators’ own opinions. The Sites and Services may contain opinions from
        time to time with regard to securities mentioned in other Sites or
        Services, and that those opinions may be different from those obtained
        by using another portion of the Sites or Services. Trading in securities
        (including, without limitation, stocks, options, ETFs and bonds)
        involves risk and volatility. Past performance is not necessarily
        indicative of future performance.
      </p>
      <p>
        <b>Gorudo’s role</b>
        <br />
        We proactively look at some pages and posts on Gorudo to make sure
        Creators follow our policies. We also investigate reports of potential
        violations. These investigations may take a while to resolve and may
        include looking at what is supported by funds received through Gorudo.{" "}
        <br />
        In most situations we will work with Creators to resolve any potential
        violations and allow the Creator to continue using Gorudo. Terminating
        accounts is not an action we take lightly and is done in only the most
        extreme cases. <br />
        Gorudo reserves the right to remove a Creator or Member from
        participating in a particular membership but will not result in an
        automatic removal of the Creator or Member from the platform, unless
        there is an independent basis for their removal from the platform.
        Please let us know if you see potential violations of our policies. You
        can learn how to report them by email at{" "}
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>. <br />
        As a global company based in the Hong Kong with operations in other
        countries, we must comply with economic sanctions and trade
        restrictions, including those implemented by the Office of Foreign
        Assets Control (“OFAC”) of the U.S. Department of the Treasury. This
        means that Gorudo cannot take part in transactions that involve
        designated people, places, or items that originate from those places, as
        determined by agencies like OFAC. Without limiting the foregoing, by
        using the Service, you represent and warrant that: (a) you are not
        located in, ordinarily resident in, or organized under the laws of any
        jurisdiction that is subject to a comprehensive U.S. Government embargo
        ("Embargoed Jurisdiction"); (b) you are not subject to any sanctions
        administered by an agency of the U.S. Government, any other government,
        or the United Nations (collectively, “Sanctions”); (c) you are not owned
        or controlled, directly or indirectly, by any person that is subject to
        Sanctions, or that is located in, ordinarily resident in, or organized
        under the laws of, any Embargoed Jurisdiction; (d) none of your
        officers, managers, directors, shareholders or authorized
        representatives is subject to Sanctions, or is located in, ordinarily
        resident in, or organized under the laws of, any Embargoed Jurisdiction,
        or is owned or controlled, directly or indirectly, by any person that is
        subject to Sanctions or that is located in, ordinarily resident in, or
        organized under the laws of, any Embargoed Jurisdiction. You further
        covenant that the foregoing shall be true during the entire period of
        this agreement. If you access or use the Service outside the United
        States, you are solely responsible for ensuring that your access and use
        of the Service in such country, territory or jurisdiction does not
        violate any applicable laws. <br />
        We are constantly testing out new features with the goal of making
        Gorudo better. We may add or remove features, and often test features
        with a random subset of our community. If we believe a feature is
        significantly different from these terms, then we explain those
        differences in the test. <br />
        With your permission, we may give other websites or services the ability
        to verify information about your Gorudo account or perform actions on
        your behalf. This permission is asked for when you connect your Gorudo
        account to these other websites or services. You can learn more in
        our Privacy Policy.
      </p>
      <p>
        <b>Account deletion</b>
        <br />
        You can permanently delete your account at any time by sending an email
        to support@gorudo.io. We may request some personal information to verify
        the account. We can terminate or suspend your account at any time at our
        discretion. We can also cancel any membership subscriptions and remove
        any descriptions, posts or benefits at our discretion. You may not bring
        a claim against us for suspending or terminating another person’s
        account, and you agree you will not bring such a claim. If you try to
        bring such a claim, you are responsible for the damages caused,
        including attorneys fees and costs. These terms remain in effect even if
        you no longer have an account.
      </p>
      <p>
        <b>Your content</b>
        <br />
        You keep full ownership of all creations that you offer on Gorudo, but
        we need licenses from you to operate Gorudo effectively. By posting
        creations on Gorudo you grant us a royalty-free, perpetual, irrevocable,
        non-exclusive, sublicensable, worldwide license to use, reproduce,
        distribute, perform, publicly display or prepare derivative works of
        your content. The purpose of this license is strictly limited to allow
        us to provide and promote memberships to your Members. We will never try
        to steal your content or use them in an exploitative way. You may not
        post content that infringe others’ intellectual property or proprietary
        rights. We may ask you for consent verification for collaborators
        depicted in content funded through Gorudo. Members may not use content
        posted by Creators in any way not authorized by the Creator.
      </p>
      <p>
        <b>Your third-party apps</b>
        <br />
        You may grant Gorudo access to your third-party accounts, such as
        Google, YouTube, Facebook, Instagram, Twitter, and Twitch etc., in order
        for some Gorudo features to operate. Each time you connect your
        third-party account, that third-party account will present a page that
        describes the information that Gorudo can access. At any time, you can
        revoke Gorudo’s access to those accounts using the respective third
        party’s security settings page. These are the links for each service:{" "}
        <br /> <br />
        <table>
          <tbody>
            <tr>
              <td>Google</td>
              <td>
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>YouTube</td>
              <td>
                <a href="https://www.youtube.com/t/terms" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://policies.google.com/terms" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>
                <a href="https://www.facebook.com/terms.php" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://www.facebook.com/policy.php" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Instagram</td>
              <td>
                <a
                  href="https://help.instagram.com/581066165581870"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </td>
              <td>
                <a
                  href="https://help.instagram.com/402411646841720"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td>
                <a href="https://twitter.com/en/tos" target="_blank">
                  Terms of Service
                </a>
              </td>
              <td>
                <a href="https://twitter.com/en/privacy" target="_blank">
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
            <tr>
              <td>Twitch</td>
              <td>
                <a
                  href="https://www.twitch.tv/p/legal/terms-of-service/"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </td>
              <td>
                <a
                  href="https://www.twitch.tv/p/legal/privacy-notice/"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </td>
              <td>
                <a
                  href="https://security.google.com/settings/security/permissions"
                  target="_blank"
                >
                  Revoke Gorudo’s Access
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </p>
      <p>
        <b>Gorudo’s creations</b>
        <br />
        Our creations are protected by copyright, trademark and trade secret
        laws. Some examples of our creations are the text on the site, our logo,
        and our codebase. We grant you a license to use our logo and other
        copyrights or trademarks to promote your Gorudo page. You may not
        otherwise use, reproduce, distribute, perform, publicly display or
        prepare derivative works of our creations unless we give you permission
        in writing. Please ask if you have any questions.
      </p>
      <p>
        <b>Indemnity</b>
        <br />
        You will indemnify us from all losses and liabilities, including legal
        fees, that arise from these terms or relate to your use of Gorudo. We
        reserve the right to exclusive control over the defense of a claim
        covered by this clause. If we use this right then you will help us in
        our defense. Your obligation to indemnify under this clause also applies
        to our subsidiaries, affiliates, officers, directors, employees, agents
        and third party service providers.
      </p>
      <p>
        <b>Warranty disclaimer</b>
        <br />
        <b>
          Gorudo is provided “as is” and without warranty of any kind. Any
          warranty of merchantability, fitness for a particular purpose,
          non-infringement, and any other warranty is excluded to the greatest
          extent permitted by law. The disclaimers of warranty under this clause
          also apply to our subsidiaries, affiliates and third party service
          providers.
        </b>
      </p>
      <p>
        <b>Limit of liability</b>
        <br />
        <b>
          To the extent permitted by law, we are not liable to you for any
          incidental, consequential or punitive damages arising out of these
          terms, or your use or attempted use of Gorudo. To the extent permitted
          by law, our liability for damages is limited to the amount of money we
          have earned through your use of Gorudo. We are specifically not liable
          for loss associated with unfulfilled benefits and from losses caused
          by conflicting contractual agreements. For this clause “we” and “our”
          is defined to include our subsidiaries, affiliates, officers,
          directors, employees, agents and third party service providers.
        </b>
      </p>
      <p>
        <b>Dispute resolution</b>
        <br />
        We encourage you to contact us if you have an issue. If a dispute does
        arise out of these terms or in relation to your use of Gorudo, then the
        dispute will be resolved in accordance with Hong Kong laws. Both
        parties’ consent to the exclusive jurisdiction and venue of the Hong
        Kong courts for the purpose of resolving any such dispute. Hong Kong
        law, excluding its conflict of law provisions, governs these terms, all
        other Gorudo policies, and any dispute that arises between you and
        Gorudo.
      </p>
      <p>
        <b>App Terms</b>
        <br />
        You are responsible for providing the mobile device, wireless service
        plan, software, Internet connections, and/or other equipment or services
        that you need to download, install, and use the App. We do not guarantee
        that the App can be accessed and used on any particular device or with
        any particular service plan. We do not guarantee that the App or Service
        will be available in any particular geographic location. <br />
        The following terms and conditions apply to you only if you are using
        the App from the Apple App Store: To the extent the other terms and
        conditions of these Terms are less restrictive than, or otherwise
        conflict with, the terms and conditions of this paragraph, the more
        restrictive or conflicting terms and conditions in this paragraph apply,
        but solely with respect to your use of the App from the Apple App Store.
        You acknowledge and agree that these Terms are solely between you and
        the Company, not Apple, and that Apple has no responsibility for the App
        or content thereof. Your use of the App must comply with the App Store’s
        applicable terms of use. You acknowledge that Apple has no obligation
        whatsoever to furnish any maintenance and support services with respect
        to the App. In the event of any failure of the App to conform to any
        applicable warranty, you may notify Apple, and Apple will refund the
        purchase price, if any, for the App to you. To the maximum extent
        permitted by applicable law, Apple will have no other warranty
        obligation whatsoever with respect to the App, and any other claims,
        losses, liabilities, damages, costs or expenses attributable to any
        failure to conform to any warranty will be solely governed by these
        Terms. You acknowledge that Apple is not responsible for addressing any
        claims of yours or any third party relating to the App or your
        possession and/or use of the App, including, but not limited to: (a)
        product liability claims, (b) any claim that the App fails to conform to
        any applicable legal or regulatory requirement, and (c) claims arising
        under consumer protection or similar legislation. You acknowledge that,
        in the event of any third-party claim that the App or your possession
        and use of that App infringes that third party’s intellectual property
        rights, the Company, not Apple, will be solely responsible for the
        investigation, defense, settlement and discharge of any such
        intellectual property infringement claim to the extent required by these
        Terms. You must comply with applicable third-party terms of agreement
        when using the App. You acknowledge and agree that Apple, and Apple’s
        subsidiaries, are third-party beneficiaries of these Terms as they
        relate to your use of the App, and that, upon your acceptance of these
        Terms, Apple will have the right (and will be deemed to have accepted
        the right) to enforce these Terms against you as a third-party
        beneficiary thereof. <br />
      </p>
      <p>
        <b>Everything else</b>
        <br />
        These terms and any referenced policies are the entire agreement between
        you and us, and supersede all prior agreements. If any provision of
        these terms is held to be unenforceable, then that provision is modified
        to the extent necessary to enforce it. If a provision cannot be
        modified, it is severed from these terms, and all other provisions
        remain in force. If either party fails to enforce a right provided by
        these terms, it does not waive the ability to enforce any rights in the
        future. We may sometimes make changes to these terms and policies. If we
        make material changes that adversely affect your rights, then we will
        let you know before the changes come into effect. Continuing to use
        Gorudo after a change means you accept the new terms or policies. These
        terms are an agreement with us. If you use accessibility tools and have
        questions or concerns, please contact us at 
        <a href="mailto:support@gorudo.io">support@gorudo.io</a>.
      </p>
      <p>
        <div style={{ textAlign: "center" }}>
          <b>Data Processing Agreement - Creator Privacy Promise</b>
        </div>
        Gorudo empowers creators on our platform (“Creators”) to start
        membership businesses by directly connecting with their biggest fans and
        turning them into paying members (“Members”). <br />
        To facilitate this direct connection with Gorudo and enable membership
        rewards and obligations to be fulfilled, Gorudo provides the personal
        data of Members (“Member Data”) to Creators. Creators then process
        Member Data in order to provide Member any and all products or services
        as part of that Creator’s membership business on Gorudo (collectively
        known as “Membership Services”). Gorudo requires all Creators to agree
        to this Data Processing Agreement (“Privacy Promise”) to ensure that
        Creators respect the privacy rights of Members when processing Member
        Data. <br />
        This Privacy Promise is between Gorudo and Creators, taking effect from
        the moment a Gorudo account is created and applies exclusively to the
        Member Data collected by Gorudo and provided to Creators for the purpose
        of running a membership business with Gorudo. <br />
        This Privacy Promise is a part of Gorudo’s Terms of Use and Privacy
        Policy and will outline certain requirements for Creators to process
        Member Data during and beyond their relationship with Gorudo.
      </p>
      <p>
        <b>I. Definitions.</b>
        <br />
        <ul>
          <li>
            <b>"Data Protection Legislation"</b> means all applicable laws
            relating to privacy and the processing of personal data that may
            exist in any relevant jurisdiction, including, where applicable, the
            guidance and codes of practice issued by the supervisory
            authorities. Data Protection Legislation includes, but is not
            limited to, European Directives 95/46/EC and 2002/58/EC (as amended
            by Directive 2009/136/EC) and any legislation and/or regulation
            implementing or made pursuant to them, or which amends, replaces,
            re-enacts or consolidates any of them, including the General Data
            Protection Regulation (Regulation (EU) 2016/279).
          </li>
          <li>
            <b>"Good Industry Practice"</b> means exercising the same skill,
            expertise and judgement and using facilities and resources of a
            similar quality as would be expected from a person who:(a) is
            skilled and experienced in providing the services in question,
            seeking in good faith to comply with his contractual obligations and
            seeking to avoid liability arising under any duty of care that might
            reasonably apply; (b) takes all proper and reasonable care and is
            diligent in performing his obligations; and (c) complies with the
            Data Protection Legislation.
          </li>
          <li>
            The terms{" "}
            <b>
              "data controller", "data processor", “subprocessor”, "data
              subject", "personal data", "processing", and "appropriate
              technical and organizational measures"
            </b>{" "}
            shall be interpreted in accordance with Directive 95/46/EC, or other
            applicable Data Protection Legislation, in the relevant
            jurisdiction.
          </li>
        </ul>
      </p>
      <p>
        <b>II. Scope.</b> The parties agree that Gorudo is a data controller and
        that Creator is a data processor in relation to Member Data that Creator
        processes in the course of providing Membership Services. The subject
        matter of the data processing, the types of personal data processed, and
        the categories of data subjects will be defined by, and/or limited to,
        those necessary to carry out the Membership Services. The processing to
        which this Privacy Promise applies will be carried out by Creator upon
        leaving the Gorudo platform. The subject matter, duration, nature, and
        purpose of the processing of the personal data as well as the type of
        personal data and categories of data subjects covered by this Privacy
        Promise are as follows:
        <ul>
          <li>
            <b>The subject matter</b> of the data processing is Member Data
          </li>
          <li>
            <b>The duration</b> of the processing is for as long as Creator
            holds Member Data.
          </li>
          <li>
            <b>The nature and purpose</b> of the processing under this Privacy
            Promise is limited to a Creator’s fulfillment of Membership Services
            to the Member.
          </li>
          <li>
            <b>The type</b> of personal data covered by this agreement is
            contact information, including but not limited to username, email
            address, shipping address and pledge amounts.
          </li>
          <li>
            <b>The category</b> of the data subjects are website users who sign
            up for memberships on Gorudo.
          </li>
        </ul>
      </p>
      <p>
        <b>III. Data Protection.</b> Creator shall adhere to the following
        requirements:
        <ul>
          <li>
            <b>Processing as Instructed.</b> Creators will process Member Data
            only in accordance with the Gorudo Terms of Use, Privacy Policy and
            this Privacy Promise and only in compliance with Data Protection
            Legislation. The nature and purpose of the processing shall be
            limited to that necessary to carry out such instructions, and not
            for Creator's own purposes, or for any other purpose except as
            required by law. If Creator is required by law to process the
            personal data for any other purpose, Creator will inform Gorudo of
            such requirement prior to the processing unless prohibited by law
            from doing so.
          </li>
          <li>
            <b>Extent of Processing.</b> Creator will process the personal data
            only to the extent, and in such manner, as is necessary for the
            provision of Membership Services.{" "}
          </li>
          <li>
            <b>Appropriate Technical and Organizational Measures.</b> Creator
            will implement and maintain appropriate technical and organizational
            measures designed to protect the personal data against unauthorized
            or unlawful processing and against accidental loss, destruction,
            damage, theft, alteration or disclosure. The measures shall be
            appropriate to the harm which might result from any unauthorized or
            unlawful processing, accidental loss, destruction, damage or theft
            of the personal data and having regard to the nature of the personal
            data which is to be protected and as a minimum shall be in
            accordance with the Data Protection Legislation and Good Industry
            Practice
          </li>
          <li>
            <b>Transfer to Third Parties.</b> Creator will not give access to or
            transfer any personal data to any third party (including any
            affiliates, group companies or subcontractors) without the prior
            consent of Gorudo. Creator must also ensure the reliability and
            competence of such third parties, its employees or agents who may
            have access to the personal data processed in the provision of
            Membership Services, and must include in any contract with such
            third party provisions protecting Member which are equivalent to
            those in this Privacy Promise and the Terms of Use and as are
            required by applicable Data Protection Legislation.
          </li>
          <li>
            <b>Reliability and Competence of Creator Personnel.</b> Creator will
            take reasonable steps to ensure the reliability and competence of
            any Creator personnel who have access to Member Data. Creator will
            ensure that all Creator personnel required to access the personal
            data are informed of the confidential nature of the personal data
            and comply with the obligations set out in this Privacy Promise.
          </li>
          <li>
            <b>
              Acknowledgement of Data Protection Legislation and Assistance.
            </b>{" "}
            Creator will take all reasonable steps to assist Gorudo in complying
            with applicable Data Protection Legislation. For example, Creator
            will promptly inform Gorudo in writing if it receives: (i) a request
            from a data subject concerning any personal data; or (ii) a
            complaint, communication, or request relating to Member’s
            obligations under Data Protection Legislation.
          </li>
          <li>
            <b>
              Destruction or Return of Property Upon Membership Services
              Completion.
            </b>{" "}
            Creator will not retain any of the personal data for longer than is
            necessary to provide Membership Services. At the end of Membership
            Services, or upon Member’s request, Creator will securely destroy or
            return (at Member’s election) the personal data to Member.
          </li>
          <li>
            <b>Loss or Security Breach.</b> If Creator becomes aware of any
            accidental, unauthorized or unlawful destruction, loss, alteration,
            or disclosure of, or access to Member Data processed by Creator in
            the course of providing Membership Services, it will do the
            following:
            <ul>
              <li>
                <b>Provide notice to Gorudo.</b> Creator shall promptly and
                without undue delay notify Gorudo and provide Gorudo with: a
                detailed description of the Loss or Security Breach; the type of
                data that was the subject of the Loss or Security Breach; the
                identity of each affected person if known, and the steps Creator
                has taken or will take in order to mitigate and remediate such
                Security Breach, in each case as promptly as such information
                can be collected or otherwise becomes available (as well as
                periodic updates to this information and any other information
                Gorudo may reasonably request relating to the Loss or Security
                Breach); and
              </li>
              <li>
                <b>Investigate the Matter promptly.</b> Creator shall promptly
                take action, at its own expense, to investigate the Loss or
                Security Breach and to identify, prevent and mitigate the
                effects of the Loss or Security Breach and to carry out
                appropriate recovery actions to remedy the Loss or Security
                Breach.
              </li>
            </ul>
          </li>
          <li>
            <b>Compliance with Data Protection Legislation.</b> Creator shall
            comply at all times with and assist Gorudo in complying with its
            applicable obligations under Data Protection Legislation. Creator
            shall provide reasonable information requested by Gorudo to
            demonstrate compliance with the obligations set out in this Privacy
            Promise. Creator will notify Gorudo immediately if, in Creator's
            opinion, an instruction for the processing of personal data given by
            Gorudo violates any country’s data privacy legislation.
          </li>
        </ul>
      </p>
    </div>
  );
}
