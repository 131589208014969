import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Card3 from '../Card/Card3';
import MembershipTire from '../MembershipTire';
import Spacing from '../Spacing';
import { v4 as uuidv4 } from 'uuid';
import {
  creatorPublicViewNewsletter,
  getCreatorPublicDetails,
  getPublicCreatorTier,
  newsletterTier,
} from '../../api/services/creator/publicView';
import { tagStringToArray } from '../../helper/tagStringToArray';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { textLimit } from '../../helper/TextLimit';
import { removeHtml } from '../../helper/removeHtml';

const ReadMore = ({ count, children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div>
      <p>{isReadMore ? text.slice(0, count) : text}</p>
      <span onClick={toggleReadMore} className="cs-coninue_reading">
        {isReadMore ? ' Read More...' : ' Show Less'}
      </span>
    </div>
  );
};

export default function CreatorPublicView() {
  const { t } = useTranslation();
  const { username } = useParams();
  const [creatorloading, setCreatorLoading] = useState(false);
  const [creator, setCreator] = useState({});
  const [tierLoading, setTierLoading] = useState(false);
  const [tierErrorMessage, setTierErrorMesage] = useState('');
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);
  const [newsletterTiers, setNewsletterTiers] = useState([]);
  const [newsletterTierLoading, setNewsletterTierLoading] = useState([]);
  const [newsletterloading, setNewsletterLoading] = useState(false);
  const [newsletters, setNewsletters] = useState([]);

  const creatorPublicDetails = async () => {
    setCreatorLoading(true);
    const res = await getCreatorPublicDetails(username);
    setCreator(res.data.data);
    setCreatorLoading(false);
  };
  // get creator tier
  const getCreatorTier = async () => {
    setTierLoading(true);
    const res = await getPublicCreatorTier(username);
    if (res.data.success === 1) {
      setTiers(res.data.data);
    } else {
      setTierErrorMesage(res.data.message);
    }
    setTierLoading(false);
  };

  // get creator public view newsletter
  const newsletter = async () => {
    setNewsletterLoading(true);
    const res = await creatorPublicViewNewsletter(username);
    setNewsletters(tagStringToArray(res.data.data));
    setNewsletterLoading(false);
  };

  // get newsletter tier
  const getNewsletterTiers = async id => {
    setNewsletterTierLoading(true);
    const res = await newsletterTier(id);
    setNewsletterTiers(res.data.data);
    setNewsletterTierLoading(false);
  };
  useEffect(() => {
    creatorPublicDetails();
    getCreatorTier();
    newsletter();
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <Spacing lg="90" md="90" />
      <div className="cs-contaienr_sm">
        <Card3 creator={creator} creatorloading={creatorloading} />
        <div id="membership_tire">
          <Spacing lg="80" md="60" />
          <MembershipTire
            creator_id={creator.id}
            loading={tierLoading}
            tiers={tiers}
            tierErrorMessage={tierErrorMessage}
            title={t('SelectMembershipTier')}
          />
        </div>
        <Spacing lg="80" md="60" />
        <div className="cs-subscription_notice">
          <h2>
            <svg
              width={28}
              height={30}
              viewBox="0 0 28 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.9464 24.5223C23.7583 23.0825 23.637 16.9026 23.6347 16.7467V12.6007C23.6347 8.6104 21.1963 5.17917 17.7314 3.71819C17.7239 1.66667 16.053 0 14 0C11.9469 0 10.2761 1.66677 10.2686 3.71809C6.80375 5.17908 4.36537 8.61031 4.36537 12.6006V16.7466C4.36311 16.9025 4.24174 23.0824 1.05355 24.5222C0.690359 24.6863 0.490751 25.0816 0.574722 25.4712C0.658594 25.8609 1.00315 26.1392 1.40173 26.1392H9.4841C9.64979 27.0506 10.0808 27.8986 10.7399 28.5854C11.6154 29.4975 12.7732 29.9999 13.9998 29.9999C15.2266 29.9999 16.3844 29.4975 17.2598 28.5854C17.919 27.8985 18.35 27.0505 18.5156 26.1392H26.598C26.9965 26.1392 27.341 25.8609 27.425 25.4712C27.5091 25.0816 27.3096 24.6863 26.9464 24.5223ZM22.7467 21.5618C23.0819 22.5778 23.5564 23.5982 24.2294 24.4473H3.77051C4.44336 23.5983 4.91792 22.578 5.25306 21.5618H22.7467ZM14 1.69191C14.9287 1.69191 15.7139 2.31594 15.9596 3.16652C15.3266 3.03525 14.6712 2.96603 14 2.96603C13.3288 2.96603 12.6734 3.0352 12.0404 3.16652C12.2862 2.31598 13.0714 1.69191 14 1.69191ZM6.05728 16.7518V12.6006C6.05728 8.22099 9.62033 4.65795 14 4.65795C18.3797 4.65795 21.9427 8.22099 21.9427 12.6006V16.7587C21.9438 16.886 21.9636 18.2234 22.3007 19.8697H5.69909C6.03635 18.2218 6.05625 16.882 6.05728 16.7518ZM14 28.3081C12.7088 28.3081 11.5818 27.3772 11.2201 26.1392H16.7798C16.418 27.3772 15.2913 28.3081 14 28.3081Z"
                fill="currentColor"
              />
              <path
                d="M14.0003 5.84473C10.3605 5.84473 7.39941 8.80576 7.39941 12.4454C7.39941 12.9126 7.77819 13.2914 8.2454 13.2914C8.7126 13.2914 9.09138 12.9126 9.09138 12.4454C9.09138 9.7387 11.2935 7.53664 14.0003 7.53664C14.4675 7.53664 14.8463 7.15787 14.8463 6.69066C14.8462 6.2234 14.4675 5.84473 14.0003 5.84473Z"
                fill="currentColor"
              />
            </svg>
            {t('creator_public_view_text_1')}
          </h2>
          <p>{t('creator_public_view_text_2')}</p>
          <p>{t('creator_public_view_text_3')}</p>
          <p>{t('creator_public_view_text_4')}</p>
        </div>
        <Spacing lg="30" md="30" />
        <div className="cs-text_box">
          <h2>
            {t('creator_public_view_text_5')} {creator.name_show}
          </h2>
          <p>{creator.about_yourself}</p>
        </div>
        <Spacing lg="80" md="60" />
        <h2
          style={{ fontSize: '30px', textAlign: 'center', marginBottom: '0' }}
        >
          {t('creator_public_view_text_6')}
        </h2>
        <Spacing lg="20" md="20" />
        {newsletters.map(item => (
          <div key={item.id}>
            <div className="cs-card cs-style4">
              <div className="cs-card_meta">
                <img src={item.creator_image} alt="User" />
                <div style={{ color: '#353535' }}>
                  <p
                    className="cs-m0"
                    style={{
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                    }}
                  >
                    {item.name_show}
                  </p>
                  <p className="cs-m0">
                    {moment(item.created_at).format('LT')} ;{' '}
                    {moment(item.created_at).format('D MMM Y')}
                  </p>
                </div>
              </div>
              <div className="cs-card_info">
                {item.visibility !== '1' ? (
                  <div className="cs-card_thumb">
                    <img src="/images/thumb_1.jpeg" alt="" />
                    <div className="cs-card_overlay">
                      <svg
                        width="30"
                        height="45"
                        viewBox="0 0 30 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                          fill="white"
                        />
                      </svg>
                      <h3>{t('creator_public_view_text_7')}</h3>
                      <a
                        className="cs-card_btn"
                        onClick={() => {
                          setModal(true);
                          getNewsletterTiers(item.id);
                        }}
                      >
                        {t('creator_public_view_text_8')}
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    {item.image && (
                      <div className="cs-card_thumb">
                        <img src={item.image} alt="" />
                      </div>
                    )}
                  </>
                )}
                <h3 className="cs-break_all">{item.title}</h3>
                <p className="cs-break_all">
                  {item.visibility === '1'
                    ? textLimit(removeHtml(item.detail), 600)
                    : item.teaser}
                </p>
                <div className="cs-tags">
                  {item.tag.map(item => (
                    <a key={uuidv4()} className="cs-tag">
                      {item}
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <Spacing lg="25" md="25" />
          </div>
        ))}
        <Spacing lg="35" md="35" />
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <MembershipTire
                    creator_id={creator.id}
                    tiers={newsletterTiers}
                    loading={newsletterTierLoading}
                    tierErrorMessage={tierErrorMessage}
                    title={t('SelectMembershipTier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
