import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import Spacing from '../Spacing';
import LoadingSpinner from '../common/LoadingSpinner';
import EmptyData from '../common/EmptyData';
import formatPrice from '../../helper/formatPrice';
import { useTranslation } from 'react-i18next';

export default function MembershipTire({
  title,
  tiers,
  loading,
  creator_id,
  tierErrorMessage = '',
}) {
  const { t } = useTranslation();
  const [invitationCode, setInvitationCode] = useState([]);
  // if featires
  const isFeatures = (array, item) => {
    const result = array?.find(({ featuresName }) => featuresName === item);
    return !result ? 'cs-disabled' : '';
  };
  const handleChoose = id => {
    const findIndex = invitationCode.findIndex(code => code.tierId === id);
    const code = invitationCode[findIndex].invitation_code;
    const redirectUrl = `https://member.gorudo.io/subscribe-creator?creator_id=${creator_id}&tier_id=${id}&invitation_code=${code}`;
    window.location.href = redirectUrl;
  };

  // genearte invitation code array
  useEffect(() => {
    setInvitationCode(
      tiers.map(item => {
        return {
          tierId: item.id,
          invitation_code: '',
        };
      }),
    );
  }, [tiers]);
  return (
    <>
      <h2 style={{ fontSize: '30px', textAlign: 'center', marginBottom: '0' }}>
        {title}
      </h2>
      <Spacing lg="40" md="40" />

      <div className={`cs-tires ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {tiers.map((item, index) => (
          <form
            onSubmit={e => {
              e.preventDefault();
              handleChoose(item.id);
            }}
            key={index}
          >
            <div className={`cs-tire ${item.is_popular === '1' && 'active'}`}>
              <h3 className="cs-tire_title">{item.title}</h3>
              <ul className="cs-tire_list">
                <li>
                  <Icon icon="charm:tick" />
                  {t('broadcast')}
                </li>
                <li className={isFeatures(item.features, 'Trade Diary')}>
                  <Icon icon="charm:tick" />
                  {t('trade_dairy')}
                </li>
                <li className={isFeatures(item.features, 'Open Position')}>
                  <Icon icon="charm:tick" />
                  {t('open_position')}
                </li>
                <li className={isFeatures(item.features, 'Resources + FAQ')}>
                  <Icon icon="charm:tick" />
                  {t('resources_faq')}
                </li>
                <li className={isFeatures(item.features, 'Newsletter')}>
                  <Icon icon="charm:tick" />
                  {t('newsletter')}
                </li>
                <li className={isFeatures(item.features, 'Watchlist')}>
                  <Icon icon="charm:tick" />
                  {t('watchlist')}
                </li>
                <li className={isFeatures(item.features, 'Webinar / Video')}>
                  <Icon icon="charm:tick" />
                  {t('webinar_video')}
                </li>
              </ul>
              {item.price !== '0' && (
                <>
                  <Spacing lg="15" md="15" />
                  <div className="cs-promo_code">
                    <div>{t('invitation_code')}</div>
                    <input
                      type="text"
                      placeholder={t('enter_code')}
                      onChange={e => {
                        const findIndex = invitationCode.findIndex(
                          code => code.tierId === item.id,
                        );
                        let updatedCodes = [...invitationCode];
                        updatedCodes[findIndex].invitation_code =
                          e.target.value;
                        setInvitationCode(updatedCodes);
                      }}
                    />
                  </div>
                  <Spacing lg="20" md="20" />
                  <div className="cs-tire_info">
                    {item.member_qouta} {t('seat_left')}
                  </div>
                </>
              )}
              <Spacing lg="10" md="10" />
              <div className="cs-tire_price">
                <span style={{ fontWeight: '400', fontSize: '17px' }}>$</span>
                <span style={{ fontWeight: '700', fontSize: '25px' }}>
                  {item.price}
                </span>
                <span style={{ opacity: '0.68' }}>/{t('month')}*</span>
              </div>
              <div>
                <span>
                  {formatPrice(
                    item.price_convert ?? '0',
                    item.price_convert_code ?? '0',
                  )}
                </span>
              </div>
              <Spacing lg="20" md="20" />
              <button className={`cs-tire_btn`}>{t('choose')}</button>
            </div>
          </form>
        ))}
      </div>
      {!tiers.length && !loading ? (
        <EmptyData message={tierErrorMessage} />
      ) : null}
    </>
  );
}
